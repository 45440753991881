import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Button,
  Text,
  Loading,
  InlineError,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import editCategory from "../../../Assets/Images/Svgs/editCategory.svg";
import { useTranslation } from "react-i18next";

const AddMessage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [messageValue, setMessageValue] = useState("");
  const handleChangeMessage = useCallback((newValue) => {
    setMessageValue(newValue);
    setMessageError("");
  }, []);
  const [messageError, setMessageError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const [categoriesError, setCategoriesError] = useState("");
  const [placementError, setPlacementError] = useState("");
  const [sentimentsError, setSentimentsError] = useState("");

  const [optionsLanguages, setOptionsLanguages] = useState([
    { label: "English", value: "English" },
    { label: "Arabic", value: "Arabic" },
  ]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const handleSelectLanguage = useCallback((newValue) => {
    setSelectedLanguage(newValue);
    setLanguageError("");
  }, []);
  const [languageError, setLanguageError] = useState("");
  useEffect(() => {
    fetchData();
  }, []);
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [optionsPlacements, setOptionsPlacements] = useState([]);
  const [optionsSentiments, setOptionsSentiments] = useState([]);
  const [selectedSentiments, setSelectedSentiments] = useState([]);
  const [selectedPlacement, setSelectedPlacement] = useState("");
  const handleSelectChangePlacement = useCallback((newValue) => {
    setSelectedPlacement(newValue);
    setPlacementError("");
  }, []);
  async function fetchData() {
    let responseCategories = "";
    let responseSentiments = "";
    let responsePlacements = "";
    try {
      responseCategories = await axios.get(`/admin/template-categories`);
      responseCategories?.data &&
        setOptionsCategories(
          responseCategories.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseSentiments = await axios.get(`/admin/sentiments`);
      responseSentiments?.data &&
        setOptionsSentiments(
          responseSentiments.data.map((item, index) => {
            return {
              label: item.name,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responsePlacements = await axios.get(`/admin/placements`);
      responsePlacements?.data &&
        setOptionsPlacements(
          responsePlacements.data.map((item, index) => {
            return {
              label: item.name,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    setIsSaving(false);
  }

  return (
    <Page
      title={t("templates_add_template")}
      breadcrumbs={[
        {
          onAction: () => navigate(`/${i18n.language}/admin/templates-items`),
        },
      ]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <Text variant="bodyMd" as="p" fontWeight="semibold">
            {t("dashboard_language")}
          </Text>
          <Select
            options={optionsLanguages}
            value={selectedLanguage}
            onChange={handleSelectLanguage}
            placeholder={t("please_select")}
            error={languageError}
          />
          <Text variant="bodyMd" as="p" fontWeight="semibold">
            {t("templates_message")}
          </Text>
          <TextField
            value={messageValue}
            onChange={handleChangeMessage}
            error={messageError}
          />
          {/* <Editor
            apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
            value={messageValue}
            init={{
              branding: false,
              height: 200,
              menubar: false,
              plugins: [
                "textcolor advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount image emoticons",
              ],
              toolbar:
                "emoticons undo redo | formatselect | bold italic forecolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
              file_browser_callback_types: "image",
              file_picker_callback: function (callback, value, meta) {
                if (meta.filetype == "image") {
                  var input = document.getElementById("my-file");
                  input.click();
                  input.onchange = function () {
                    var file = input.files[0];
                    console.log(file);
                    var reader = new FileReader();
                    reader.onload = function (e) {
                      console.log("name", e.target.result);
                      const form_data = new FormData();
                      form_data.append("image", file);
                      return axios
                        .post("/admin/v1/images", form_data)
                        .then((res) => {
                          callback(res.data.path, {
                            alt: file.name,
                          });
                        })
                        .catch((err) => console.log(""));
                    };
                    reader.readAsDataURL(file);
                  };
                }
              },
            }}
            onEditorChange={handleChangeMessage}
          /> */}
          {/* <InlineError message={messageError} /> */}
          <Text variant="bodyMd" as="p" fontWeight="semibold">
            {t("templates_placement")}
          </Text>
          <Select
            options={optionsPlacements}
            onChange={handleSelectChangePlacement}
            value={selectedPlacement}
            placeholder={t("please_select")}
            error={placementError}
          />
          <FormLayout.Group>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                {t("templates_categories")}
                <span style={{ marginLeft: "0.4rem" }}>
                  <img
                    src={editCategory}
                    width={19}
                    onClick={() =>
                      navigate(`/${i18n.language}/admin/templates-categories`)
                    }
                    style={{ marginLeft: "0.2rem", cursor: "pointer" }}
                  ></img>
                </span>
              </Text>
              <MultiSelect
                options={optionsCategories}
                value={selectedCategories}
                onChange={setSelectedCategories}
                labelledBy={t("please_select")}
              />
              <InlineError message={categoriesError} />
            </FormLayout>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                {t("templates_sentiments")}
              </Text>
              <MultiSelect
                options={optionsSentiments}
                value={selectedSentiments}
                onChange={setSelectedSentiments}
                labelledBy={t("please_select")}
              />
              <InlineError message={sentimentsError} />
            </FormLayout>
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: t("save"),
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      !messageValue ||
      !selectedLanguage ||
      !selectedPlacement ||
      !selectedCategories.length ||
      !selectedSentiments.length
    ) {
      !messageValue && setMessageError(t("field_required"));
      !selectedLanguage && setLanguageError(t("field_required"));
      !selectedPlacement && setPlacementError(t("field_required"));
      !selectedCategories.length && setCategoriesError(t("field_required"));
      !selectedSentiments.length && setSentimentsError(t("field_required"));
    } else {
      setIsSaving(true);
      const bodyObj = {
        message: messageValue,
        language: selectedLanguage,
        placement_id: selectedPlacement,
        template_categories: selectedCategories.map((item) => item.value),
        sentiments: selectedSentiments.map((item) => item.value),
        active: 1,
      };

      axios
        .post("/admin/messages", bodyObj)
        .then((res) => {
          navigate("/admin/templates-items");
        })
        .catch((err) => console.log(""));
    }
  }
};
export default AddMessage;
