import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Page,
  Filters,
  Image,
  ChoiceList,
  Loading,
  Card,
  Text,
  Spinner,
  ButtonGroup,
  Button,
  Modal,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import emptyListImage from "../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import { useNavigate } from "react-router-dom";

const ListAdminUsers = () => {
  const navigate = useNavigate();
  const refBoolPage = useRef(true);
  const [popupActive, setPopupActive] = useState(false);
  const format = "YYYY-MM-DD HH:mm:ss";
  const perPage = 200;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const handleChangePage = (event, value) => {
    refBoolPage.current = true;
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleEdit = (e) => {
    navigate(`/admin/admin-users/${e.currentTarget.id}`);
  };
  const [ts, setTs] = useState("");
  useEffect(() => {
    getData();
  }, [page, sortState, availability, queryValue, ts]);

  const getData = () => {
    axios
      .get(
        `/admin/administrators?filter[${
          availability === "" ? "id" : availability
        }]=${queryValue}per_page=${perPage}&page=${
          refBoolPage.current ? page : 1
        }`
      )
      .then((result) => {
        !result.data.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.meta.total / perPage));
        result.data.data &&
          setItems(
            result.data.data.map((item, index) => [
              item?.id && item.id,
              item?.first_name && item.first_name,
              item?.last_name && item.last_name,
              <ButtonGroup>
                <Button onClick={handleEdit} id={item.id}>
                  Edit
                </Button>
              </ButtonGroup>,
            ])
          );
        setIsLoading(false);
      })
      .catch(function (error) {});
  };
  function handleFiltersQueryChange(queryValue) {
    refBoolPage.current = false;
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Email", value: "email" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <Text variant="headingMd" as="h6">
        No results found
      </Text>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  const handleExport = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}admin/export`);
  };
  return (
    <Page
      fullWidth
      title="Admin Users"
      primaryAction={{
        content: "Add Admin User",
        onAction: () => navigate("/admin/admin-users/new"),
      }}
    >
      <Card>
        {loadingMarkup}
        <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <Text variant="bodyMd" as="p" fontWeight="medium">
              ID
            </Text>,
            <Text variant="bodyMd" as="p" fontWeight="medium">
              First Name
            </Text>,
            <Text variant="bodyMd" as="p" fontWeight="medium">
              Last Name
            </Text>,
            <Text variant="bodyMd" as="p" fontWeight="medium"></Text>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
          stickyHeader
          hasZebraStripingOnData
          increasedTableDensity
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function findElementByID(array, id) {
    return array.find((element) => {
      return element.id === id;
    });
  }
};

export default ListAdminUsers;
