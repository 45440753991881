import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Page,
  Filters,
  Image,
  ChoiceList,
  Loading,
  Card,
  Text,
  Spinner,
  ButtonGroup,
  Button,
  Modal,
  InlineError,
  Thumbnail,
  DropZone,
  Stack,
  Toast,
  FormLayout,
  Icon,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import { useNavigate } from "react-router-dom";
import { NoteMinor, CirclePlusMinor } from "@shopify/polaris-icons";
import template from "../../../Assets/Files/kenchat_questions_answers_template.csv";
import Drawer from "react-modern-drawer";
import EditComponent from "./EditComponent";
import { useTranslation } from "react-i18next";

const ListProcesses = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const refBoolPage = useRef(true);
  const [popupActive, setPopupActive] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const format = "YYYY-MM-DD HH:mm:ss";
  const perPage = 200;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const handleChangePage = (event, value) => {
    refBoolPage.current = true;
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const refId = useRef("");
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    document.body.style.overflow = "visible";
    refId.current = "";
  };
  const refreshPage = () => {
    document.body.style.overflow = "visible";
    refId.current = "";
    setIsOpen((prevState) => !prevState);
    getData();
  };
  const handleEdit = (e) => {
    // navigate(`/admin/processes-items/${e.currentTarget.id}`);
    refId.current = parseInt(e.currentTarget.id);
    if (isOpen === false) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "visible";
      }
    }
    setIsOpen((prevState) => !prevState);
  };
  const handleEditSteps = (e) => {
    navigate(
      `/${i18n.language}/admin/processes-items/${e.currentTarget.id}/steps`
    );
  };
  const [ts, setTs] = useState("");

  function handleFiltersQueryChange(queryValue) {
    refBoolPage.current = false;
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: t("filter_by"),
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: t("id"), value: "id" },
            { label: t("templates_message"), value: "question" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <Text variant="headingMd" as="h6">
        {t("no_results_found")}
      </Text>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  const handleExport = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}admin/export`);
  };
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [file, setFile] = useState();
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFile((file) => acceptedFiles[0]),
    []
  );
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !file && <DropZone.FileUpload actionHint="Accepts .csv" />;
  const uploadedFile = file && (
    <Stack>
      <Thumbnail
        size="small"
        alt={file.name}
        source={
          validImageTypes.includes(file.type)
            ? window.URL.createObjectURL(file)
            : NoteMinor
        }
      />
      <div>
        {file.name}{" "}
        <Text variant="bodySm" as="p">
          {file.size} bytes
        </Text>
      </div>
    </Stack>
  );
  const [fileError, setFileError] = useState("");
  const handleSubmit = () => {
    if (!file) {
      setFileError("Please upload a csv file");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("file", file);
      axios
        .post(`admin/templates-import`, form_data)
        .then((result) => {
          setIsSaving(false);
          setFile();
          setFileError("");
          setTs(+new Date());
          toggleActiveToast();
          toggleActive();
        })
        .catch((err) => console.log(err));
    }
  };
  const [activeToast, setActiveToast] = useState(false);
  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const toastMarkup = activeToast ? (
    <Toast
      content="Questions have been imported successfully"
      onDismiss={toggleActiveToast}
    />
  ) : null;
  const [isButtonClicked, setIsButtonClicked] = useState("");
  const [language, setLanguage] = useState("en");
  const handleLanguage = (param) => {
    setLanguage(param);
  };
  useEffect(() => {
    getData();
  }, [page, sortState, availability, queryValue, ts, language, i18n.language]);

  const getData = () => {
    axios
      .get(
        `/admin/processes?filter[${
          availability === "" ? "question" : availability
        }]=${queryValue}&page[size]=${perPage}&page[number]=${
          refBoolPage.current ? page : 1
        }${language ? `&language=${language}` : ""}`
      )
      .then((result) => {
        console.log(result.data.data);
        !result.data.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.meta.total / perPage));
        result.data.data &&
          setItems(
            result.data.data.map((item, index) => [
              // item?.id && item.id,
              item?.question && item.question[Object.keys(item.question)[0]],
              <ButtonGroup>
                <Button onClick={handleEditSteps} id={item.id} primary>
                  {t("steps")}
                </Button>
                <Button onClick={handleEdit} id={item.id}>
                  {t("edit")}
                </Button>
              </ButtonGroup>,
            ])
          );
        setIsLoading(false);
      })
      .catch(function (error) {});
  };
  return (
    <Page
      title={t("nav_processes")}
      primaryAction={
        <Button
          icon={CirclePlusMinor}
          primary
          onClick={() =>
            navigate(`/${i18n.language}/admin/processes-items/new`)
          }
        >
          {t("processes_add_process")}
        </Button>
      }
    >
      <Drawer
        // ref={ref}
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="bla bla bla"
        // zIndex={100}
        size={500}
        style={{
          paddingTop: "3rem",
          overflow: "visible",
          paddingBottom: "10rem",
        }}
        duration={700}
        // variant="persistent"
      >
        {refId.current && (
          <EditComponent
            processId={refId.current}
            handleRefreshPage={refreshPage}
          />
        )}
      </Drawer>
      <Card>
        {loadingMarkup}
        <Card.Section>
          <FormLayout>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* <Button
                pressed={language === ""}
                onClick={() => handleLanguage("")}
              >
                All
              </Button> */}
              {/* <div style={{ marginLeft: "0.5rem" }}> */}
              <Button
                pressed={language === "en"}
                onClick={() => handleLanguage("en")}
              >
                {t("lg_english")}
              </Button>
              {/* </div> */}
              <div className={i18n.language === "en" ? "ms-2" : "me-2"}>
                <Button
                  pressed={language === "ar"}
                  onClick={() => handleLanguage("ar")}
                >
                  {t("lg_arabic")}
                </Button>
              </div>
            </div>
            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleFiltersClearAll}
              queryPlaceholder={t("type_search")}
            />
          </FormLayout>
        </Card.Section>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            // <Text variant="bodyMd" as="p" fontWeight="medium">
            //   ID
            // </Text>,
            <Text variant="bodyMd" as="p" fontWeight="medium">
              {t("question")}
            </Text>,
            <Text variant="bodyMd" as="p" fontWeight="medium"></Text>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
          stickyHeader
          hasZebraStripingOnData
          increasedTableDensity
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
      <Modal open={popupActive} loading={true}></Modal>
      <Modal
        large
        open={active}
        onClose={toggleActive}
        title="Import templates by CSV"
        primaryAction={{
          content: "Submit",
          onAction: handleSubmit,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: toggleActive,
          },
        ]}
      >
        <Modal.Section>
          <Stack vertical>
            <p>
              Download a{" "}
              <a href={template} download="kenchat_questions_answers_template">
                sample CSV template
              </a>{" "}
              to see an example of the format required.
            </p>
            <DropZone
              accept=".csv"
              type="file"
              allowMultiple={false}
              onDrop={handleDropZoneDrop}
            >
              {uploadedFile}
              {fileUpload}
            </DropZone>
            <InlineError message={fileError} fieldID="myFieldID" />
          </Stack>
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function findElementByID(array, id) {
    return array.find((element) => {
      return element.id === id;
    });
  }
};

export default ListProcesses;
