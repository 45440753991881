import React, { useCallback, useEffect } from "react";
// import "@shopify/polaris/build/esm/styles.css";
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";
import { AppProvider, Toast } from "@shopify/polaris";
import "./Assets/Styles/globals.css";
import "./Assets/Styles/react-tagsinput.css";
import Login from "./Pages/Authentication/Login";
import ForgotPassword from "./Pages/Authentication/ForgotPassword";
import Profile from "./Pages/Profile";
import ResetPassword from "./Pages/Authentication/ResetPassword";
import Users from "./Pages/Users/Users";
import AddUser from "./Pages/Users/AddUser";
import NavigationLayout from "./Components/NavigationLayout";
import ListProcessesCategories from "./Pages/Processes/Categories/ListProcessCategories";
import AddProcessCategory from "./Pages/Processes/Categories/AddProcessCategory";
import EditProcessCategory from "./Pages/Processes/Categories/EditProcessCategory";
import ListProcesses from "./Pages/Processes/Items/ListProcesses";
import AddProcess from "./Pages/Processes/Items/AddProcess";
import EditProcess from "./Pages/Processes/Items/EditProcess";
import ListProcessSteps from "./Pages/Processes/Steps/ListProcessSteps";
import AddStep from "./Pages/Processes/Steps/AddStep";
import EditStep from "./Pages/Processes/Steps/EditStep";
import ListMessageCategories from "./Pages/Templates/Categories/ListMessageCategories";
import AddMessageCategory from "./Pages/Templates/Categories/AddMessageCategory";
import EditMessageCategory from "./Pages/Templates/Categories/EditMessageCategory";
import ListMessages from "./Pages/Templates/Items/ListMessages";
import AddMessage from "./Pages/Templates/Items/AddMessage";
import EditMessage from "./Pages/Templates/Items/EditMessage";
import ListQuestions from "./Pages/QuestionsAndAnswers/ListQuestions";
import AddQuestion from "./Pages/QuestionsAndAnswers/AddQuestion";
import EditQuestion from "./Pages/QuestionsAndAnswers/EditQuestion";
import ListUsers from "./Pages/Users/ListUsers";
import EditUser from "./Pages/Users/EditUser";
import ListAdminUsers from "./Pages/AdminUsers/ListAdminUsers";
import AddAdminUser from "./Pages/AdminUsers/AddAdminUser";
import EditAdminUser from "./Pages/AdminUsers/EditAdminUser";
import FrontAuthenticate from "./Pages/FrontAuthenticate";
import Dashboard from "./Pages/Dashboard";
import { useTranslation } from "react-i18next";
import ListQuestionsCategories from "./Pages/QuestionsAndAnswers/Categories/ListQuestionsCategories";
import AddQuestionCategory from "./Pages/QuestionsAndAnswers/Categories/AddQuestionCategory";
import "bootstrap/dist/css/bootstrap.css";

function App() {
  const { t, i18n, match } = useTranslation();

  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }

  useEffect(() => {
    var pathname = new URL(window.location.href).pathname;
    i18n.changeLanguage(pathname.slice(1, 3));
    document.dir = i18n.dir();
    document.documentElement.lang = i18n.language;
  }, [i18n, i18n.language]);

  return (
    // <AppProvider
    //   theme={{
    //     colors: {
    //       surface: "#111213",
    //       onSurface: "#111213",
    //       interactive: "#2e72d2",
    //       secondary: "#111213",
    //       primary: "#0d6efd",
    //       critical: "#d82c0d",
    //       warning: "#ffc453",
    //       highlight: "#5bcdda",
    //       success: "#008060",
    //       decorative: "#ffc96b",
    //     },
    //   }}
    // >

    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/password/reset" element={<ResetPassword />} />

      <Route
        path={`${i18n.language}/admin/processes-categories`}
        element={
          <NavigationLayout>
            <ListProcessesCategories />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/processes-categories/new`}
        element={
          <NavigationLayout>
            <AddProcessCategory />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/processes-categories/:id`}
        element={
          <NavigationLayout>
            <EditProcessCategory />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/processes-items`}
        element={
          <NavigationLayout>
            <ListProcesses />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/processes-items/new`}
        element={
          <NavigationLayout>
            <AddProcess />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/processes-items/:id`}
        element={
          <NavigationLayout>
            <EditProcess />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/processes-items/:id/steps`}
        element={
          <NavigationLayout>
            <ListProcessSteps />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/processes-items/:id/steps/new`}
        element={
          <NavigationLayout>
            <AddStep />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/processes-items/:processId/steps/:stepId`}
        element={
          <NavigationLayout>
            <EditStep />
          </NavigationLayout>
        }
      />

      <Route
        path={`${i18n.language}/admin/templates-categories`}
        element={
          <NavigationLayout>
            <ListMessageCategories />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/templates-categories/new`}
        element={
          <NavigationLayout>
            <AddMessageCategory />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/templates-categories/:id`}
        element={
          <NavigationLayout>
            <EditMessageCategory />
          </NavigationLayout>
        }
      />

      <Route
        path={`${i18n.language}/admin/templates-items`}
        element={
          <NavigationLayout>
            <ListMessages />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/templates-items/new`}
        element={
          <NavigationLayout>
            <AddMessage />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/templates-items/:id`}
        element={
          <NavigationLayout>
            <EditMessage />
          </NavigationLayout>
        }
      />

      <Route
        path={`${i18n.language}/admin/questions-categories`}
        element={
          <NavigationLayout>
            <ListQuestionsCategories />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/questions-categories/new`}
        element={
          <NavigationLayout>
            <AddQuestionCategory />
          </NavigationLayout>
        }
      />

      <Route
        path={`${i18n.language}/admin/questions`}
        element={
          <NavigationLayout>
            <ListQuestions />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/questions/new`}
        element={
          <NavigationLayout>
            <AddQuestion />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/questions/:id`}
        element={
          <NavigationLayout>
            <EditQuestion />
          </NavigationLayout>
        }
      />

      <Route
        path={`${i18n.language}/admin/users`}
        element={
          <NavigationLayout>
            <ListUsers />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/users/new`}
        element={
          <NavigationLayout>
            <AddUser />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/users/:id`}
        element={
          <NavigationLayout>
            <EditUser />
          </NavigationLayout>
        }
      />

      <Route
        path={`${i18n.language}/admin/admin-users`}
        element={
          <NavigationLayout>
            <ListAdminUsers />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/admin-users/new`}
        element={
          <NavigationLayout>
            <AddAdminUser />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/admin-users/:id`}
        element={
          <NavigationLayout>
            <EditAdminUser />
          </NavigationLayout>
        }
      />

      <Route
        path={`${i18n.language}/admin/users`}
        element={
          <NavigationLayout>
            <Users />
          </NavigationLayout>
        }
      />
      <Route
        path={`${i18n.language}/admin/users/new`}
        element={
          <NavigationLayout>
            <AddUser />
          </NavigationLayout>
        }
      />
      <Route path="/admin/front-authenticate" element={<FrontAuthenticate />} />
      <Route
        path={`${i18n.language}/admin/dashboard`}
        element={
          <NavigationLayout>
            <Dashboard />
          </NavigationLayout>
        }
      />
    </Routes>
    // </AppProvider>
  );
}
export default App;
