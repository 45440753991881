import React, { useCallback, useState } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Text,
  Loading,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddMessageCategory = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [nameEnValue, setNameEnValue] = useState("");
  const handleChangeNameEn = useCallback((newValue) => {
    setNameEnValue(newValue);
    setNameError("");
  }, []);
  const [nameArValue, setNameArValue] = useState("");
  const handleChangeNameAr = useCallback((newValue) => {
    setNameArValue(newValue);
    setNameError("");
  }, []);
  const [nameError, setNameError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Page
      title="Add Category"
      breadcrumbs={[
        {
          onAction: () =>
            navigate(`/${i18n.language}/admin/templates-categories`),
        },
      ]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                Name En
              </Text>
              <TextField
                value={nameEnValue}
                onChange={handleChangeNameEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                Name Ar
              </Text>
              <TextField
                value={nameArValue}
                onChange={handleChangeNameAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={nameError} />
          {/* <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div> */}
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (!nameEnValue && !nameArValue) {
      setNameError(t("field_required"));
    } else {
      setIsSaving(true);
      const bodyObj = {
        name: {
          ...(nameEnValue && { en: nameEnValue }),
          ...(nameArValue && { ar: nameArValue }),
        },
      };

      axios
        .post("/admin/process-categories", bodyObj)
        .then((res) => {
          navigate(`/${i18n.language}/admin/templates-categories`);
        })
        .catch((err) => console.log(""));
    }
  }
};
export default AddMessageCategory;
