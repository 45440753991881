import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Page,
  Filters,
  Image,
  ChoiceList,
  Loading,
  Card,
  Text,
  Spinner,
  ButtonGroup,
  Button,
  Modal,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Drawer from "react-modern-drawer";
import EditComponent from "./EditComponent";
import { useTranslation } from "react-i18next";
import { CirclePlusMinor } from "@shopify/polaris-icons";

const ListProcessSteps = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const refBoolPage = useRef(true);
  const [popupActive, setPopupActive] = useState(false);
  const format = "YYYY-MM-DD HH:mm:ss";
  const perPage = 200;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const handleChangePage = (event, value) => {
    refBoolPage.current = true;
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const refId = useRef("");
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    document.body.style.overflow = "visible";
    refId.current = "";
  };
  const refreshPage = () => {
    document.body.style.overflow = "visible";
    refId.current = "";
    setIsOpen((prevState) => !prevState);
    getData();
  };
  const handleEdit = (e) => {
    // navigate(`/admin/processes-items/${id}/steps/${e.currentTarget.id}`);
    refId.current = parseInt(e.currentTarget.id);
    if (isOpen === false) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "visible";
      }
    }
    setIsOpen((prevState) => !prevState);
  };

  const [ts, setTs] = useState("");
  useEffect(() => {
    getData();
  }, [page, sortState, availability, queryValue, ts, i18n.language]);

  const getData = () => {
    axios
      .get(
        `/admin/process-answers/${id}?filter[${
          availability === "" ? "question" : availability
        }]=${queryValue}&page[size]=${perPage}&page[number]=${
          refBoolPage.current ? page : 1
        }`
      )
      .then((result) => {
        console.log(result.data.data);
        !result.data.data.length ? setIsListEmpty(true) : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.meta.total / perPage));
        result.data.data &&
          setItems(
            result.data.data.map((item, index) => [
              item?.id && item.id,
              item?.answer && item.answer[Object.keys(item.answer)[0]],
              <ButtonGroup>
                <Button onClick={handleEdit} id={item.id}>
                  {t("edit")}
                </Button>
              </ButtonGroup>,
            ])
          );
        setIsLoading(false);
      })
      .catch(function (error) {});
  };
  function handleFiltersQueryChange(queryValue) {
    refBoolPage.current = false;
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: t("filter_by"),
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: t("id"), value: "id" },
            { label: t("step"), value: "answer" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <Text variant="headingMd" as="h6">
        {t("no_results_found")}
      </Text>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  const handleExport = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}admin/export`);
  };
  return (
    <Page
      title={t("steps")}
      breadcrumbs={[
        {
          onAction: () => navigate(`/${i18n.language}/admin/processes-items`),
        },
      ]}
      primaryAction={
        <Button
          icon={CirclePlusMinor}
          primary
          onClick={() =>
            navigate(`/${i18n.language}/admin/processes-items/${id}/steps/new`)
          }
        >
          {t("steps_add_step")}
        </Button>
      }
    >
      <Drawer
        // ref={ref}
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="bla bla bla"
        // zIndex={100}
        size={700}
        style={{
          paddingTop: "3rem",
          overflow: "visible",
          paddingBottom: "10rem",
        }}
        duration={700}
        // variant="persistent"
      >
        {refId.current && (
          <EditComponent
            stepId={refId.current}
            handleRefreshPage={refreshPage}
          />
        )}
      </Drawer>
      <Card>
        {loadingMarkup}
        <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
            queryPlaceholder={t("type_search")}
          />
        </Card.Section>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <Text variant="bodyMd" as="p" fontWeight="medium">
              {t("id")}
            </Text>,
            <Text variant="bodyMd" as="p" fontWeight="medium">
              {t("step")}
            </Text>,
            <Text variant="bodyMd" as="p" fontWeight="medium"></Text>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
          stickyHeader
          hasZebraStripingOnData
          increasedTableDensity
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function findElementByID(array, id) {
    return array.find((element) => {
      return element.id === id;
    });
  }
};

export default ListProcessSteps;
