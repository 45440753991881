import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  InlineError,
  Text,
  Page,
  AppProvider,
  FormLayout,
  Select,
  Card,
  PageActions,
  Button,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import InputEmoji from "react-input-emoji";
import editCategory from "../../Assets/Images/Svgs/editCategory.svg";
import { useTranslation } from "react-i18next";

function AddQuestion() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [valueQuestion, setvalueQuestion] = useState("");
  const [questionError, setquestionError] = useState("");
  const [valueAnswer, setvalueAnswer] = useState("");
  const [answerError, setAnswerError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [selected, setSelected] = useState("");
  const handleSelectChange = useCallback((value) => {
    setSelected(value);
    setCategoryError("");
  }, []);
  const handleChangeAnswer = (newValue) => {
    setvalueAnswer(newValue);
    setAnswerError("");
  };
  const [options, setOptions] = useState([]);
  const handleChangeQuestion = (newValue) => {
    setvalueQuestion(newValue);
    setquestionError("");
  };
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const handleSelectChangeLanguage = useCallback(
    (value) => setSelectedLanguage(value),
    []
  );
  const optionsLanguage = [
    { label: "Arabic", value: "Arabic" },
    { label: "English", value: "English" },
  ];

  useEffect(() => {
    axios
      .get(`admin/categories`)
      .then((result) => {
        setOptions(
          result.data.map((item) => ({
            label: item.name[Object.keys(item.name)[0]],
            value: item.id,
          }))
        );
      })
      .catch((err) => console.log(err));
  }, []);

  function handleOnEnter(text) {
    console.log("enter", text);
  }
  return (
    <AppProvider>
      <Page
        title={t("questions_add_questions")}
        breadcrumbs={[
          { onAction: () => navigate(`/${i18n.language}/admin/questions`) },
        ]}
      >
        <Card sectioned>
          <FormLayout>
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("dashboard_language")}
            </Text>
            <Select
              options={optionsLanguage}
              onChange={handleSelectChangeLanguage}
              value={selectedLanguage}
            />
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("category")}
              <span style={{ marginLeft: "0.4rem" }}>
                <img
                  src={editCategory}
                  width={19}
                  onClick={() => navigate(`/admin/questions-categories`)}
                  style={{ marginLeft: "0.2rem", cursor: "pointer" }}
                ></img>
              </span>
            </Text>
            <Select
              options={options}
              onChange={handleSelectChange}
              value={selected}
              error={categoryError}
              placeholder={t("please_select")}
            />
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("question")}
            </Text>
            <Editor
              apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
              value={valueQuestion}
              init={{
                branding: false,
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help|ltr rtl|code",
              }}
              onEditorChange={handleChangeQuestion}
            />
            <InlineError message={questionError} />
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("answer")}
            </Text>
            <InputEmoji
              value={valueAnswer}
              onChange={handleChangeAnswer}
              cleanOnEnter
              onEnter={handleOnEnter}
              placeholder="Type a message"
            />
            <InlineError message={answerError} />
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: t("save"),
            onClick: handleSave,
            loading: isSaving && true,
          }}
        />
      </Page>
    </AppProvider>
  );

  function handleSave() {
    if (!valueQuestion || !valueAnswer || !selected) {
      !valueAnswer && setAnswerError(t("field_required"));
      !valueQuestion && setquestionError(t("field_required"));
      !selected && setCategoryError(t("please_select_category"));
    } else {
      setIsSaving(true);
      const bodyObj = {
        question: {},
        answer: {},
        category_id: selected,
      };
      console.log(valueAnswer);
      bodyObj["question"][selectedLanguage] = valueQuestion;
      bodyObj["answer"][selectedLanguage] = valueAnswer;
      axios
        .post("/admin/templates", bodyObj)
        .then((res) => {
          navigate(`/${i18n.language}/admin/questions`);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
}
export default AddQuestion;
