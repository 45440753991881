import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Text,
  Loading,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditComponent = (props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const handleChangeNameEn = (newValue) => {
    setItem({ ...item, nameEn: newValue });
    setNameError("");
  };
  const handleChangeNameAr = (newValue) => {
    setItem({ ...item, nameAr: newValue });
    setNameError("");
  };
  const [nameError, setNameError] = useState("");
  const [isSaving, setIsSaving] = useState(true);

  const [item, setItem] = useState({
    nameEn: "",
    nameAr: "",
  });
  useEffect(() => {
    axios
      .get(`/admin/process-categories/${props.categId}`)
      .then((result) => {
        setIsSaving(false);
        setItem({
          nameEn: result?.data?.name?.en ? result.data.name.en : "",
          nameAr: result?.data?.name?.ar ? result.data.name.ar : "",
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
    // title="Edit Category"
    // breadcrumbs={[
    //   { onAction: () => navigate(`/admin/processes-categories`) },
    // ]}
    >
      {isSaving ? <Loading /> : null}
      {/* <Card sectioned> */}
      <FormLayout>
        <FormLayout.Group condensed>
          <FormLayout>
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("name_english")}
            </Text>
            <TextField
              value={item.nameEn}
              onChange={handleChangeNameEn}
              align="left"
            />
          </FormLayout>
          <FormLayout>
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("name_arabic")}
            </Text>
            <TextField
              value={item.nameAr}
              onChange={handleChangeNameAr}
              align="right"
            />
          </FormLayout>
        </FormLayout.Group>
        <InlineError message={nameError} />
        {/* <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail size="large" source={item.image ? item.image : ""} />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div> */}
      </FormLayout>
      {/* </Card> */}
      <PageActions
        primaryAction={{
          content: t("save"),
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: t("delete"),
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .delete(`/admin/process-categories/${props.categId}`)
                .then((result) => {
                  setIsSaving(false);
                  props.refreshPage();
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (!item.nameEn && !item.nameAr) {
      !item.nameEn && !item.nameAr && setNameError(t("field_required"));
    } else {
      setIsSaving(true);
      const bodyObj = {
        name: { en: item.nameEn, ar: item.nameAr },
      };

      axios
        .patch(`admin/process-categories/${props.categId}`, bodyObj)
        .then((res) => {
          setIsSaving(false);
          props.refreshPage();
        })
        .catch((err) => console.log(""));
    }
  }
};
export default EditComponent;
