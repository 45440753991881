import React, { useCallback, useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "../Assets/Lib/axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function FrontAuthenticate() {
  const navigate = useNavigate();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const { t, i18n } = useTranslation();

  let query = useQuery();

  console.log("aaaa=", query.get("token"));
  useEffect(() => {
    let token = GetURLParameter("token");
    console.log("token=", token);
    Cookies.set("kenchat-accesstoken", token);
    // axios
    //   .get("admin/me")
    //   .then((result) => {
    //     Cookie.set("kenchat-name", result.data.name);
    //     Cookie.set("kenchat-firstletter", result.data.name.charAt(0));
    //     axios
    //       .get("admin/company")
    //       .then((result) => {
    //         Cookie.set("kenchat-detail", result.data.name);
    //         window.location.href = "http://editqa.kenchat.net/admin/questions";
    //       })
    //       .catch((err) => console.log(err));
    //   })
    //   .catch((err) => console.log(err));
    window.location.href = `https://admin.kenchat.net/${i18n.language}/admin/dashboard`;
    // navigate("/admin/questions");
  }, []);
  return <div></div>;
  function GetURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      if (sParameterName[0] == sParam) {
        return sParameterName[1];
      }
    }
  }
}

export default FrontAuthenticate;
