import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Button,
  Thumbnail,
  Text,
  Loading,
  InlineError,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EditProcess = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const handleChangeQuestionEn = (newValue) => {
    setItem({ ...item, questionEn: newValue });
    setQuestionError("");
  };
  const handleChangeQuestionAr = (newValue) => {
    setItem({ ...item, questionAr: newValue });
    setQuestionError("");
  };
  const [questionError, setQuestionError] = useState("");
  const [isSaving, setIsSaving] = useState(true);

  const [item, setItem] = useState({
    questionEn: "",
    questionAr: "",
    category: "",
  });
  const [optionsCategories, setOptionsCategories] = useState([]);
  const handleSelectCategory = (newValue) => {
    setItem({ ...item, category: newValue });
    setCategoryError("");
  };
  const [categoryError, setCategoryError] = useState("");

  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseProcess = "";
    let responseCategories = "";

    try {
      responseCategories = await axios.get(`/admin/process-categories`);
      responseCategories?.data &&
        setOptionsCategories(
          responseCategories.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseProcess = await axios.get(`/admin/processes/${id}`);
      responseProcess?.data &&
        setItem({
          questionEn: responseProcess?.data?.question?.en
            ? responseProcess.data.question.en
            : "",
          questionAr: responseProcess?.data?.question?.ar
            ? responseProcess.data.question.ar
            : "",
          category: String(responseProcess?.data?.process_category_id),
        });
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }
  return (
    <Page
      title="Edit Process"
      breadcrumbs={[{ onAction: () => navigate("/admin/processes-items") }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <Text variant="bodyMd" as="p" fontWeight="semibold">
            Category
            <span style={{ marginLeft: "0.4rem" }}>
              <Button
                plain
                onClick={() => navigate(`/admin/processes-categories`)}
              >
                Edit
              </Button>
            </span>
          </Text>
          <Select
            options={optionsCategories}
            value={item.category}
            onChange={handleSelectCategory}
            placeholder="Please select a category"
            error={categoryError}
          />
          <FormLayout.Group>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                Process En
              </Text>
              <TextField
                value={item.questionEn}
                onChange={handleChangeQuestionEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                Process Ar
              </Text>
              <TextField
                value={item.questionAr}
                onChange={handleChangeQuestionAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={questionError} />
          {/* <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail size="large" source={item.image ? item.image : ""} />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div> */}
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .delete(`/admin/processes/${id}`)
                .then((result) => {
                  navigate(`/admin/processes-items`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if ((!item.questionEn && !item.questionAr) || !item.category) {
      !item.questionEn &&
        !item.questionAr &&
        setQuestionError("Please enter a text");
      !item.category && setCategoryError("Please select a category");
    } else {
      setIsSaving(true);
      const bodyObj = {
        question: { en: item.questionEn, ar: item.questionAr },
        process_category_id: item.category,
      };

      axios
        .patch(`admin/processes/${id}`, bodyObj)
        .then((res) => {
          navigate("/admin/processes-items");
        })
        .catch((err) => console.log(""));
    }
  }
};
export default EditProcess;
