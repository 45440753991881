import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  ResourceItem,
  ResourceList,
  Thumbnail,
  Caption,
  Modal,
  InlineError,
  DropZone,
  Checkbox,
  Stack,
  Text,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  ActionList,
  Popover,
  Select,
  Card,
  Tabs,
  PageActions,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import { useParams } from "react-router-dom";
import Select2 from "react-dropdown-select";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function EditUser() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const [valueName, setValueName] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const options = [
    { label: "Admin", value: "admin" },
    { label: "Editor", value: "editor" },
    { label: "Agent", value: "agent" },
  ];
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const [selected, setSelected] = useState("admin");
  const [popupActive, setPopupActive] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [listOfTeams, setlistOfTeams] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [lastArrayLength, setLastArrayLength] = useState(0);
  const [selectedValues, setSelectedValues] = useState([]);
  useEffect(() => {
    Promise.all([
      axios.get(`/admin/users/${id}`),
      axios.get(`/admin/teams`),
      axios.get(`/admin/users/${id}/teams`),
    ]).then(function (responses) {
      const responseUsers = responses[0];
      const responseTeams = responses[1];
      const responseAssignedTeams = responses[2];

      setValueName(responseUsers.data.name);
      setValueEmail(responseUsers.data.email);
      setValuePassword(responseUsers.data.password);
      setSelected(responseUsers.data.account_type);

      let users = [];
      for (var i = 0; i < responseTeams.data.length; i++) {
        users.push({
          label:
            responseTeams.data[i].name +
            " ( ID : " +
            responseTeams.data[i].id +
            " )",
          value: String(responseTeams.data[i].id),
        });
      }
      setlistOfTeams(users);

      let usersTeam = [];
      for (var i = 0; i < responseAssignedTeams.data.length; i++) {
        usersTeam.push({
          label:
            responseAssignedTeams.data[i].name +
            " ( ID : " +
            responseAssignedTeams.data[i].id +
            " )",
          value: String(responseAssignedTeams.data[i].id),
        });
      }
      setLastArrayLength(usersTeam.length);
      setSelectedValues(usersTeam);
    });
  }, []);

  return (
    <AppProvider>
      <Page
        title={t("users_edit_user")}
        breadcrumbs={[
          { onAction: () => navigate(`/${i18n.language}/admin/users`) },
        ]}
      >
        <Card sectioned>
          <FormLayout>
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("name")}
            </Text>
            <TextField
              value={valueName}
              onChange={handleChangeName}
              error={nameError}
            />
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("email")}
            </Text>
            <TextField
              value={valueEmail}
              onChange={handleChangeEmail}
              error={emailError}
            />
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("password")}
            </Text>
            <TextField
              value={valuePassword}
              onChange={handleChangePassword}
              error={passwordError}
            />
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("usrs_select_account_type")}
            </Text>
            <Select
              options={options}
              onChange={handleSelectChange}
              value={selected}
            />
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("users_departments")}
            </Text>
            <Select2
              placeholder="Search user by name"
              multi
              // noDataRenderer={customNoDataRenderer}
              onChange={handleSelectUser}
              values={selectedValues}
              options={listOfTeams}
            />
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: t("save"),
            onClick: handleSave,
          }}
          secondaryActions={[
            {
              content: t("delete"),
              destructive: true,
              // loading: isSaving && true,
              onClick: () => {
                // setIsSaving(true);
                axios
                  .post(`/admin/users/delete`, { id: id })
                  .then((result) => {
                    navigate(`/${i18n.language}/admin/users`);
                  })
                  .catch((err) => console.log(err));
              },
            },
          ]}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </AppProvider>
  );

  function handleSelectUser(selectedUser) {
    let userID = 0;
    console.log("selectedUser=", selectedUser);

    let newLength = selectedUser.length;
    console.log(newLength, "----", lastArrayLength);
    if (newLength < lastArrayLength) {
      console.log("<");
      console.log(
        "deletedID=",
        selectedValues.filter(function (v) {
          return !selectedUser.includes(v);
        })[0].value
      );

      axios
        .delete(
          `/admin/users/${id}/teams/${parseInt(
            selectedValues.filter(function (v) {
              return !selectedUser.includes(v);
            })[0].value
          )}`
        )
        .then((res) => {
          setSelectedUser(selectedUser[0].value);
          //getData();
          //   setPopupActive(false);
        })
        .catch((err) => console.log(err));
    } else if (newLength > lastArrayLength && selectedUser) {
      console.log(">");

      const bodyObj = {};
      axios
        .post(
          `/admin/users/${id}/teams/${parseInt(
            selectedUser[selectedUser.length - 1].value
          )}`,
          bodyObj
        )
        .then((res) => {
          console.log("user added");
          setSelectedUser(selectedUser[selectedUser.length - 1].value);
          // getData();
          //   setPopupActive(false);
        })
        .catch((err) => console.log(err));
    }
  }

  function handleChangeName(valueName) {
    setValueName(valueName);
    setNameError("");
  }
  function handleChangeEmail(valueEmail) {
    setValueEmail(valueEmail);
    setEmailError("");
  }
  function handleChangePassword(valuePassword) {
    setValuePassword(valuePassword);
    setPasswordError("");
  }
  function handleSave() {
    setPopupActive(true);
    const bodyObj = {
      name: valueName,
      email: valueEmail,
      password: valuePassword,
      account_type: selected,
    };
    if (!valuePassword) delete bodyObj.password;
    axios
      .patch(`/admin/users/${id}`, bodyObj)
      .then((res) => {
        navigate(`/${i18n.language}/admin/users`);
      })
      .catch(function (error) {
        setPopupActive(false);
        error.response.data.errors.name &&
          setNameError(error.response.data.errors.name);
        error.response.data.errors.email &&
          setEmailError(error.response.data.errors.email);
        error.response.data.errors.password &&
          setPasswordError(error.response.data.errors.password);
      });
  }
}
export default EditUser;
