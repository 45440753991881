import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Button,
  Text,
  Loading,
  InlineError,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { useNavigate } from "react-router-dom";
import editCategory from "../../../Assets/Images/Svgs/editCategory.svg";
import { useTranslation } from "react-i18next";

const AddProcess = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [questionEnValue, setQuestionEnValue] = useState("");
  const handleChangeQuestionEn = useCallback((newValue) => {
    setQuestionEnValue(newValue);
    setQuestionError("");
  }, []);
  const [questionArValue, setQuestionArValue] = useState("");
  const handleChangeQuestionAr = useCallback((newValue) => {
    setQuestionArValue(newValue);
    setQuestionError("");
  }, []);
  const [questionError, setQuestionError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleSelectCategory = useCallback((newValue) => {
    setSelectedCategory(newValue);
    setCategoryError("");
  }, []);
  const [categoryError, setCategoryError] = useState("");
  useEffect(() => {
    axios
      .get(`/admin/process-categories`)
      .then((result) => {
        setIsSaving(false);
        setOptionsCategories(
          result.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
            };
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Page
      title={t("processes_add_process")}
      breadcrumbs={[
        { onAction: () => navigate(`/${i18n.language}/admin/processes-items`) },
      ]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <Text variant="bodyMd" as="p" fontWeight="semibold">
            {t("category")}
            <span style={{ marginLeft: "0.4rem" }}>
              <img
                src={editCategory}
                width={19}
                onClick={() =>
                  navigate(`/${i18n.language}/admin/processes-categories`)
                }
                style={{ marginLeft: "0.2rem", cursor: "pointer" }}
              ></img>
            </span>
          </Text>
          <Select
            options={optionsCategories}
            value={selectedCategory}
            onChange={handleSelectCategory}
            placeholder={t("please_select_category")}
            error={categoryError}
          />
          <FormLayout.Group>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                {t("processes_process_en")}
              </Text>
              <TextField
                value={questionEnValue}
                onChange={handleChangeQuestionEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                {t("processes_process_ar")}
              </Text>
              <TextField
                value={questionArValue}
                onChange={handleChangeQuestionAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={questionError} />
          {/* <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div> */}
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: t("save"),
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if ((!questionEnValue && !questionArValue) || !selectedCategory) {
      !questionEnValue &&
        !questionArValue &&
        setQuestionError(t("field_required"));
      !selectedCategory && setCategoryError(t("please_select_category"));
    } else {
      setIsSaving(true);
      const bodyObj = {
        question: { en: questionEnValue, ar: questionArValue },
        process_category_id: selectedCategory,
      };

      axios
        .post("/admin/processes", bodyObj)
        .then((res) => {
          navigate(`/${i18n.language}/admin/processes-items`);
        })
        .catch((err) => console.log(""));
    }
  }
};
export default AddProcess;
