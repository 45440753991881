import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  DataTable,
  Page,
  Filters,
  Image,
  ChoiceList,
  Loading,
  Card,
  Text,
  Spinner,
  ButtonGroup,
  Button,
  Modal,
  InlineError,
  Thumbnail,
  DropZone,
  Stack,
  Toast,
  Select,
  FormLayout,
  Tag,
  Icon,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import { useNavigate } from "react-router-dom";
import EllipsisText from "react-ellipsis-text";
import { Switch } from "@material-ui/core";
import template from "../../../Assets/Files/kenchat_templates_template.csv";
import Drawer from "react-modern-drawer";
import EditComponent from "./EditComponent";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import {
  ImportMinor,
  NoteMinor,
  ExportMinor,
  CirclePlusMinor,
} from "@shopify/polaris-icons";

const ListMessages = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const refBoolPage = useRef(true);
  const [popupActive, setPopupActive] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const format = "YYYY-MM-DD HH:mm:ss";
  const perPage = 1000;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [availabilityPlacement, setAvailabilityPlacement] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const handleChangePage = (event, value) => {
    refBoolPage.current = true;
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const refId = useRef("");
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    document.body.style.overflow = "visible";
    refId.current = "";
  };
  const refreshPage = () => {
    document.body.style.overflow = "visible";
    refId.current = "";
    setIsOpen((prevState) => !prevState);
    fetchData();
  };
  const handleEdit = (e) => {
    // navigate(`/admin/templates-items/${e.currentTarget.id}`);
    refId.current = parseInt(e.currentTarget.id);
    if (isOpen === false) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "visible";
      }
    }
    setIsOpen((prevState) => !prevState);
  };
  const [ts, setTs] = useState("");

  const [state, setState] = useState(false);
  const handleChange = (event) => {
    console.log(event.target.checked);
    setState(event.target.checked);
    //handleSwitch(event.target.checked);
  };

  function handleFiltersQueryChange(queryValue) {
    refBoolPage.current = false;
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }

  function handleAvailabilityChangePlacement(availabilityPlacement) {
    setAvailabilityPlacement(availabilityPlacement);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  // const filters = [
  //   {
  //     key: "availability",
  //     label: t("filter_by"),
  //     filter: (
  //       <ChoiceList
  //         title="Filter by"
  //         titleHidden
  //         choices={[
  //           { label: t("id"), value: "id" },
  //           { label: t("templates_message"), value: "message" },
  //         ]}
  //         selected={availability || []}
  //         onChange={handleAvailabilityChange}
  //       />
  //     ),
  //     shortcut: true,
  //   },
  // ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <Text variant="headingMd" as="h6">
        {t("no_results_found")}
      </Text>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );

  const [language, setLanguage] = useState("");
  const handleLanguage = (param) => {
    setLanguage(param);
  };
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [file, setFile] = useState();
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFile((file) => acceptedFiles[0]),
    []
  );
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !file && (
    <DropZone.FileUpload actionHint={t("accepts_csv")} />
  );
  const uploadedFile = file && (
    <Stack>
      <Thumbnail
        size="small"
        alt={file.name}
        source={
          validImageTypes.includes(file.type)
            ? window.URL.createObjectURL(file)
            : NoteMinor
        }
      />
      <div>
        {file.name}{" "}
        <Text variant="bodySm" as="p">
          {file.size} bytes
        </Text>
      </div>
    </Stack>
  );
  const [fileError, setFileError] = useState("");
  const handleSubmit = () => {
    if (!file) {
      setFileError("Please upload a csv file");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("file", file);
      axios
        .post(`admin/messages-import`, form_data)
        .then((result) => {
          setIsSaving(false);
          setFile();
          setFileError("");
          setTs(+new Date());
          setToastContent("Templates have been imported successfully");
          toggleActiveToast();
          toggleActive();
        })
        .catch((err) => console.log(err));
    }
  };
  const [activeToast, setActiveToast] = useState(false);
  const toggleActiveToast = useCallback(
    () => setActiveToast((activeToast) => !activeToast),
    []
  );
  const [toastContent, setToastContent] = useState("");
  const toastMarkup = activeToast ? (
    <Toast content={toastContent} onDismiss={toggleActiveToast} />
  ) : null;

  const handleChangeActive = (item) => {
    // setPopupActive(true);
    let message = JSON.parse(item);
    const bodyObj = {
      message: message.message,
      language: message.language,
      placement_id: message.placement.id,
      template_categories: message.template_categories.map((item) => item.id),
      sentiments: message.sentiments.map((item) => item.id),
      active: message.active ? 0 : 1,
    };

    axios
      .patch(`/admin/messages/${message.id}`, bodyObj)
      .then((res) => {
        // setPopupActive(false);
        // setToastContent(
        //   `This template have been ${
        //     message.active ? "deactivated" : "activated"
        //   } successfully`
        // );
        // toggleActiveToast();
        fetchData();
      })
      .catch((err) => console.log(""));
  };
  const [selected, setSelected] = useState("");
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const [options, setOptions] = useState([{ label: t("lg_all"), value: "" }]);
  const [optionsPlacements, setOptionsPlacements] = useState([
    { label: t("lg_all"), value: "" },
  ]);

  const filters = [
    {
      key: "availabilityPlacement",
      label: t("filter_by_placement"),
      filter: (
        // <Select
        //   label={t("filter_by_category")}
        //   labelInline
        //   options={options}
        //   onChange={handleSelectChange}
        //   value={selected}
        // />
        <ChoiceList
          // title="Filter by"
          titleHidden
          // choices={options}
          choices={optionsPlacements}
          selected={availabilityPlacement || []}
          onChange={handleAvailabilityChangePlacement}
        />
      ),
      shortcut: true,
    },
    {
      key: "availability",
      label: t("filter_by_category"),
      filter: (
        // <Select
        //   label={t("filter_by_category")}
        //   labelInline
        //   options={options}
        //   onChange={handleSelectChange}
        //   value={selected}
        // />
        <ChoiceList
          // title="Filter by"
          titleHidden
          // choices={options}
          choices={options}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];
  const handleExport = () => {
    window.open(
      `${
        process.env.REACT_APP_BASE_URL
      }/admin/messages-export?token=${Cookies.get("kenchat-accesstoken")}`
    );
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    sortState,
    availability,
    availabilityPlacement,
    queryValue,
    ts,
    language,
    selected,
    i18n.language,
  ]);

  async function fetchData() {
    let responseCategories = "";
    let responseList = "";
    let responsePlacements = "";
    if (options.length <= 1) {
      try {
        responseCategories = await axios.get(`admin/template-categories`);
        console.log("test=", responseCategories.data);
        responseCategories &&
          setOptions((currentObjects) =>
            currentObjects.concat([
              ...responseCategories.data.map((item, index) => {
                return {
                  key: item.id,
                  label: item.name[Object.keys(item.name)[0]],
                  value: String(item.id),
                };
              }),
            ])
          );
      } catch (error) {
        console.log(error);
      }
    }
    if (optionsPlacements.length <= 1) {
      try {
        responsePlacements = await axios.get(`admin/placements`);
        console.log("test=", responsePlacements.data);
        responsePlacements &&
          setOptionsPlacements((currentObjects) =>
            currentObjects.concat([
              ...responsePlacements.data.map((item, index) => {
                return {
                  key: item.id,
                  label: item.name,
                  value: String(item.id),
                };
              }),
            ])
          );
      } catch (error) {
        console.log(error);
      }
    }

    try {
      responseList = await axios.get(
        `/admin/messages?page[size]=${perPage}&page[number]=${
          refBoolPage.current ? page : 1
        }${
          language ? `&filter[language]=${language}` : ""
        }&filter[category]=${availability}&filter[placement_id]=${availabilityPlacement}`
      );
      !responseList.data.data.length
        ? setIsListEmpty(true)
        : setIsListEmpty(false);
      setTotalPages(Math.ceil(responseList.data.meta.total / perPage));
      responseList.data.data &&
        setItems(
          responseList.data.data.map((item, index) => [
            // item?.id && item.id,
            <Switch
              checked={item.active ? true : false}
              onChange={() => handleChangeActive(JSON.stringify(item))}
              value={item.active ? 1 : 0}
              // inputProps={{ "aria-label": "secondary checkbox" }}
            />,
            item?.message && (
              <EllipsisText
                text={item.message
                  .replace(/<[^>]+>/g, "")
                  .replace(/&nbsp;/g, "")}
                length={40}
              />
            ),
            item?.template_categories?.length ? (
              <div
                style={{ display: "flex", flexDirection: "row" }}
                key={item.id}
              >
                {item.template_categories.map((item) => {
                  return (
                    <div style={{ marginLeft: "7px" }} key={item.id}>
                      <Tag>{item.name[Object.keys(item.name)[0]]}</Tag>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            ),
            <ButtonGroup>
              <Button onClick={handleEdit} id={item.id}>
                {t("edit")}
              </Button>
            </ButtonGroup>,
          ])
        );
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }
  return (
    <Page
      title={t("nav_templates")}
      primaryAction={
        <Button
          icon={CirclePlusMinor}
          primary
          onClick={() =>
            navigate(`/${i18n.language}/admin/templates-items/new`)
          }
        >
          {t("templates_add_template")}
        </Button>
      }
      secondaryActions={[
        {
          content: t("import"),
          icon: ImportMinor,
          onAction: () => setActive(true),
        },
        {
          content: t("export"),
          icon: ExportMinor,
          onAction: handleExport,
        },
      ]}
    >
      <Drawer
        // ref={ref}
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="bla bla bla"
        // zIndex={100}
        size={500}
        style={{
          paddingTop: "3rem",
          overflow: "visible",
          paddingBottom: "10rem",
        }}
        duration={700}
        // variant="persistent"
      >
        {refId.current && (
          <EditComponent
            templateId={refId.current}
            handleRefreshPage={refreshPage}
          />
        )}
      </Drawer>
      <Card>
        {loadingMarkup}

        <Card.Section>
          <FormLayout>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                pressed={language === ""}
                onClick={() => handleLanguage("")}
              >
                {t("lg_all")}
              </Button>
              <div className={i18n.language === "en" ? "ms-2" : "me-2"}>
                <Button
                  pressed={language === "english"}
                  onClick={() => handleLanguage("english")}
                >
                  {t("lg_english")}
                </Button>
              </div>
              <div className={i18n.language === "en" ? "ms-2" : "me-2"}>
                <Button
                  pressed={language === "arabic"}
                  onClick={() => handleLanguage("arabic")}
                >
                  {t("lg_arabic")}
                </Button>
              </div>
            </div>
            {/* <Select
              label={t("filter_by_category")}
              labelInline
              options={options}
              onChange={handleSelectChange}
              value={selected}
            /> */}
            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleFiltersClearAll}
              queryPlaceholder={t("type_search")}
            />
          </FormLayout>
        </Card.Section>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            // <Text variant="bodyMd" as="p" fontWeight="medium">
            //   ID
            // </Text>,
            <Text variant="bodyMd" as="p" fontWeight="medium">
              {t("templates_active")}
            </Text>,
            <Text variant="bodyMd" as="p" fontWeight="medium">
              {t("templates_message")}
            </Text>,
            <Text variant="bodyMd" as="p" fontWeight="medium">
              {t("templates_categories")}
            </Text>,
            <Text variant="bodyMd" as="p" fontWeight="medium"></Text>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
          stickyHeader
          hasZebraStripingOnData
          increasedTableDensity
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>

      <Modal open={popupActive} loading={true}></Modal>
      <Modal
        large
        open={active}
        onClose={toggleActive}
        title={t("templates_import")}
        primaryAction={{
          content: t("submit"),
          onAction: handleSubmit,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: t("cancel"),
            onAction: toggleActive,
          },
        ]}
      >
        <Modal.Section>
          <Stack vertical>
            <p>
              {t("download")}{" "}
              <a href={template} download="kenchat_templates_template">
                {t("sample_csv")}
              </a>{" "}
              {t("see_example")}
            </p>

            <DropZone
              accept=".csv"
              type="file"
              allowMultiple={false}
              onDrop={handleDropZoneDrop}
            >
              {uploadedFile}
              {fileUpload}
            </DropZone>
            <InlineError message={fileError} fieldID="myFieldID" />
          </Stack>
        </Modal.Section>
      </Modal>
      {toastMarkup}
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return t("filter_by_category");
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
  function findElementByID(array, id) {
    return array.find((element) => {
      return element.id === id;
    });
  }
};

export default ListMessages;
