import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Button,
  Text,
  Loading,
  InlineError,
  Select,
  Scrollable,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditComponent = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [messageValue, setMessageValue] = useState("");
  const handleChangeMessage = (newValue) => {
    setItem({ ...item, message: newValue });
    setMessageError("");
  };
  const [messageError, setMessageError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const [categoriesError, setCategoriesError] = useState("");
  const [placementError, setPlacementError] = useState("");
  const [sentimentsError, setSentimentsError] = useState("");

  const [optionsLanguages, setOptionsLanguages] = useState([
    { label: "English", value: "English" },
    { label: "Arabic", value: "Arabic" },
  ]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const handleSelectLanguage = (newValue) => {
    setItem({ ...item, language: newValue });
    setLanguageError("");
  };
  const [languageError, setLanguageError] = useState("");
  const [item, setItem] = useState({
    language: "",
    message: "",
    placement: "",
    active: 1,
  });
  useEffect(() => {
    fetchData();
  }, []);
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [optionsPlacements, setOptionsPlacements] = useState([]);
  const [optionsSentiments, setOptionsSentiments] = useState([]);
  const [selectedSentiments, setSelectedSentiments] = useState([]);
  const [selectedPlacement, setSelectedPlacement] = useState("");
  const handleSelectChangePlacement = (newValue) => {
    setItem({ ...item, placement: newValue });
    setPlacementError("");
  };
  async function fetchData() {
    let responseCategories = "";
    let responseSentiments = "";
    let responsePlacements = "";
    let responseMessage = "";
    try {
      responseCategories = await axios.get(`/admin/template-categories`);
      responseCategories?.data &&
        setOptionsCategories(
          responseCategories.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseSentiments = await axios.get(`/admin/sentiments`);
      responseSentiments?.data &&
        setOptionsSentiments(
          responseSentiments.data.map((item, index) => {
            return {
              label: item.name,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responsePlacements = await axios.get(`/admin/placements`);
      responsePlacements?.data &&
        setOptionsPlacements(
          responsePlacements.data.map((item, index) => {
            return {
              label: item.name,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseMessage = await axios.get(
        `/admin/messages/${parseInt(props.templateId)}`
      );
      console.log(responseMessage.data);
      responseMessage?.data &&
        setItem({
          active: responseMessage?.data.active ? 1 : 0,
          message: responseMessage?.data.message
            ? responseMessage?.data.message
            : "",
          placement:
            responseMessage?.data.placement_id !== null
              ? String(responseMessage?.data.placement_id)
              : "",
          language: responseMessage?.data.language
            ? responseMessage?.data.language
            : "",
        });

      responseMessage?.data.sentiments &&
        setSelectedSentiments(
          responseMessage?.data.sentiments.map((item, index) => {
            return {
              label: item.name,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      responseMessage?.data.template_categories &&
        setSelectedCategories(
          responseMessage?.data.template_categories.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    setIsSaving(false);
  }

  return (
    <Page
    //   title="Edit Template"
    //   breadcrumbs={[
    //     {
    //       onAction: () => navigate(`/admin/templates-items`),
    //     },
    //   ]}
    >
      <Scrollable>
        {isSaving ? <Loading /> : null}
        {/* <Card sectioned> */}
        <FormLayout>
          <FormLayout.Group condensed>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                {t("dashboard_language")}
              </Text>
              <Select
                options={optionsLanguages}
                value={item.language}
                onChange={handleSelectLanguage}
                placeholder={t("please_select")}
                error={languageError}
              />
            </FormLayout>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                {t("templates_placement")}
              </Text>
              <Select
                options={optionsPlacements}
                onChange={handleSelectChangePlacement}
                value={item.placement}
                placeholder={t("please_select")}
                error={placementError}
              />
            </FormLayout>
          </FormLayout.Group>

          <Text variant="bodyMd" as="p" fontWeight="semibold">
            {t("templates_message")}
          </Text>
          <TextField
            value={item.message}
            onChange={handleChangeMessage}
            error={messageError}
          />
          {/* <Editor
            apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
            value={item.message}
            init={{
              branding: false,
              height: 200,
              menubar: false,
              plugins: [
                "emoticons textcolor advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount image",
              ],
              toolbar:
                "emoticons undo redo | formatselect | bold italic forecolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
              file_browser_callback_types: "image",
              file_picker_callback: function (callback, value, meta) {
                if (meta.filetype == "image") {
                  var input = document.getElementById("my-file");
                  input.click();
                  input.onchange = function () {
                    var file = input.files[0];
                    console.log(file);
                    var reader = new FileReader();
                    reader.onload = function (e) {
                      console.log("name", e.target.result);
                      const form_data = new FormData();
                      form_data.append("image", file);
                      return axios
                        .post("/admin/v1/images", form_data)
                        .then((res) => {
                          callback(res.data.path, {
                            alt: file.name,
                          });
                        })
                        .catch((err) => console.log(""));
                    };
                    reader.readAsDataURL(file);
                  };
                }
              },
            }}
            onEditorChange={handleChangeMessage}
          />
          <InlineError message={messageError} /> */}

          <FormLayout.Group condensed>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                {t("templates_categories")}
                <span style={{ marginLeft: "0.4rem" }}>
                  <Button
                    plain
                    onClick={() =>
                      navigate(`/${i18n.language}/admin/templates-categories`)
                    }
                  >
                    Edit
                  </Button>
                </span>
              </Text>
              <MultiSelect
                options={optionsCategories}
                value={selectedCategories}
                onChange={setSelectedCategories}
                labelledBy={t("please_select")}
              />
              <InlineError message={categoriesError} />
            </FormLayout>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                {t("templates_sentiments")}
              </Text>
              <MultiSelect
                options={optionsSentiments}
                value={selectedSentiments}
                onChange={setSelectedSentiments}
                labelledBy={t("please_select")}
              />
              <InlineError message={sentimentsError} />
            </FormLayout>
          </FormLayout.Group>
        </FormLayout>
        {/* </Card> */}
        <PageActions
          primaryAction={{
            content: t("save"),
            onClick: handleSave,
            loading: isSaving && true,
          }}
          secondaryActions={[
            {
              content: t("delete"),
              destructive: true,
              loading: isSaving && true,
              onClick: () => {
                setIsSaving(true);
                axios
                  .delete(`/admin/messages/${parseInt(props.templateId)}`)
                  .then((result) => {
                    setIsSaving(false);
                    props.handleRefreshPage();
                  })
                  .catch((err) => console.log(err));
              },
            },
          ]}
        />
      </Scrollable>
    </Page>
  );

  function handleSave() {
    if (
      !item.message ||
      !item.language ||
      !item.placement ||
      !selectedCategories.length ||
      !selectedSentiments.length
    ) {
      !item.message && setMessageError(t("field_required"));
      !item.language && setLanguageError(t("field_required"));
      !item.placement && setPlacementError(t("field_required"));
      !selectedCategories.length && setCategoriesError(t("field_required"));
      !selectedSentiments.length && setSentimentsError(t("field_required"));
    } else {
      setIsSaving(true);
      const bodyObj = {
        message: item.message,
        language: item.language,
        placement_id: item.placement,
        template_categories: selectedCategories.map((item) => item.value),
        sentiments: selectedSentiments.map((item) => item.value),
        active: item.active,
      };

      axios
        .patch(`/admin/messages/${parseInt(props.templateId)}`, bodyObj)
        .then((res) => {
          setIsSaving(false);
          props.handleRefreshPage();
        })
        .catch((err) => console.log(""));
    }
  }
};
export default EditComponent;
