import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  AppProvider,
  ActionList,
  Card,
  TextField,
  TextContainer,
  ContextualSaveBar,
  FormLayout,
  Modal,
  Frame,
  Layout,
  Loading,
  Navigation,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Toast,
  TopBar,
  Icon,
  Text,
  Link,
} from "@shopify/polaris";
import {
  ProfileMinor,
  LogOutMinor,
  CustomersMajor,
  CategoriesMajor,
  ChatMajor,
  QuestionMarkMajor,
  AnalyticsMajor,
  LanguageMinor,
} from "@shopify/polaris-icons";
import Cookies from "js-cookie";
import logoImg from "../Assets/Images/logoWhite.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PathToRegexp, { compile, parse } from "path-to-regexp";

function NavigationLayout(props) {
  // const { children, i18n, t, history, match } = props;

  let location = useLocation();

  const { t, i18n } = useTranslation();

  // const iconContent = () => {
  //   return (
  //     <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  //       <circle cx="10" cy="10" r="10" fill="rebeccapurple" />
  //       <circle cx="10" cy="10" r="6" fill="currentColor" />
  //       <circle cx="10" cy="10" r="3" />
  //     </svg>
  //   );
  // };
  const navigate = useNavigate();
  const defaultState = useRef({
    emailFieldValue: "dharma@jadedpixel.com",
    nameFieldValue: "Jaded Pixel",
  });
  const skipToContentRef = useRef(null);

  const [toastActive, setToastActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [nameFieldValue, setNameFieldValue] = useState(
    defaultState.current.nameFieldValue
  );
  const [emailFieldValue, setEmailFieldValue] = useState(
    defaultState.current.emailFieldValue
  );
  const [storeName, setStoreName] = useState(
    defaultState.current.nameFieldValue
  );
  const [supportSubject, setSupportSubject] = useState("");
  const [supportMessage, setSupportMessage] = useState("");

  const handleSubjectChange = useCallback(
    (value) => setSupportSubject(value),
    []
  );
  const handleMessageChange = useCallback(
    (value) => setSupportMessage(value),
    []
  );
  const handleDiscard = useCallback(() => {
    setEmailFieldValue(defaultState.current.emailFieldValue);
    setNameFieldValue(defaultState.current.nameFieldValue);
    setIsDirty(false);
  }, []);
  const handleSave = useCallback(() => {
    defaultState.current.nameFieldValue = nameFieldValue;
    defaultState.current.emailFieldValue = emailFieldValue;

    setIsDirty(false);
    setToastActive(true);
    setStoreName(defaultState.current.nameFieldValue);
  }, [emailFieldValue, nameFieldValue]);
  const handleNameFieldChange = useCallback((value) => {
    setNameFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleEmailFieldChange = useCallback((value) => {
    setEmailFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue("");
  }, []);
  const handleSearchFieldChange = useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );
  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const toggleModalActive = useCallback(
    () => setModalActive((modalActive) => !modalActive),
    []
  );

  const toastMarkup = toastActive ? (
    <Toast onDismiss={toggleToastActive} content="Changes saved" />
  ) : null;

  const userMenuActions = [
    // {
    //   items: [
    //     {
    //       content: "Your profile",
    //       icon: ProfileMinor,
    //       onAction: () => navigate("/profile"),
    //     },
    //   ],
    // },
    {
      items: [
        {
          content: t("logout"),
          icon: LogOutMinor,

          onAction: () => {
            Cookies.set("kenchat-accesstoken", null);
            i18n.changeLanguage("en");
            navigate("/");
          },
        },
        // {
        //   content: i18n.language === "en" ? "Arabic" : "English",
        //   icon: LanguageMinor,
        //   onAction: () => {
        //     let str = window.location.href.substring(
        //       window.location.href.indexOf(`/${i18n.language}/`) + 3
        //     );
        //     navigate(`/${i18n.language === "en" ? "ar" : "en"}${str}`);
        //     i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
        //     i18n.dir();
        //   },
        // },
      ],
    },
  ];
  const customIcon = {
    viewBox: "0 0 20 20",
    body: '<g transform="scale(0.5)"><path d="M16 0 C6 0 2 4 2 14 L2 22 L6 24 L6 30 L26 30 L26 24 L30 22 L30 14 C30 4 26 0 16 0 M9 12 A4.5 4.5 0 0 1 9 21 A4.5 4.5 0 0 1 9 12 M23 12 A4.5 4.5 0 0 1 23 21 A4.5 4.5 0 0 1 23 12"></path></g>',
  };
  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: handleSave,
      }}
      discardAction={{
        onAction: handleDiscard,
      }}
    />
  ) : null;
  const generateLanguage = (locale, location) => {
    const ROUTE = "/:locale/:path*";
    const definePath = compile(ROUTE);
    const routeComponents = PathToRegexp(ROUTE).exec(location.pathname);

    let subPaths = null;
    if (routeComponents && routeComponents[2]) {
      subPaths = routeComponents[2].split("/");
    }

    return definePath({
      locale,
      path: subPaths,
    });
  };
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };
  // const secondaryMenuMarkup = (
  //   <Text
  //     variant="bodyMd"
  //     as="p"
  //     fontWeight="bold"
  //     onClick={() => {
  //       let str = window.location.href.substring(
  //         window.location.href.indexOf(`/${i18n.language}/`) + 3
  //       );
  //       navigate(`/${i18n.language === "en" ? "ar" : "en"}${str}`);
  //       i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
  //       i18n.dir();
  //     }}
  //   >
  //     {i18n.language === "en" ? "Arabic" : "English"}
  //   </Text>
  // );
  const secondaryMenuMarkup = (
    // <TopBar.Menu
    //   activatorContent={
    <b
      style={{ cursor: "pointer" }}
      className={i18n.language === "en" ? "me-3" : "ms-3"}
      onClick={() => {
        console.log("aaa");
        let str = window.location.href.substring(
          window.location.href.indexOf(`/${i18n.language}/`) + 3
        );
        navigate(`/${i18n.language === "en" ? "ar" : "en"}${str}`);
        i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
        // window.location.reload();
        // i18n.dir();
      }}
    >
      {i18n.language === "en" ? "عربي" : "English"}
    </b>
  );
  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={Cookies.get("kenchat-name") && Cookies.get("kenchat-name")}
      // initials={
      //   <div>
      //     <Link to={generateLanguage("vn", location)}>
      //       <button onClick={() => changeLanguage("en")}>english</button>
      //     </Link>

      //     <Link to={generateLanguage("en", location)}>
      //       <button onClick={() => changeLanguage("ar")}>arabic</button>
      //     </Link>
      //   </div>
      // }
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const searchResultsMarkup = (
    <ActionList
      items={[
        { content: "Shopify help center" },
        { content: "Community forums" },
      ]}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Search"
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      secondaryMenu={secondaryMenuMarkup}
      // searchResultsVisible={searchActive}
      // searchField={searchFieldMarkup}
      // searchResults={searchResultsMarkup}
      // onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
      logoSuffix
    />
  );

  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            onClick: () => navigate(`/${i18n.language}/admin/dashboard`),
            label: t("nav_dashboard"),
            icon: AnalyticsMajor,
            selected: window.location.href.indexOf("/admin/dashboard") > -1,
          },
          {
            onClick: () => navigate(`/${i18n.language}/admin/questions`),
            label: t("nav_questions"),
            icon: QuestionMarkMajor,
            selected: window.location.href.indexOf("/admin/questions") > -1,
          },

          {
            url: "/",
            onClick: () => navigate(`/${i18n.language}/admin/processes-items`),
            label: t("nav_processes"),
            icon: CategoriesMajor,
            selected:
              window.location.href.indexOf("/admin/processes-categories") >
                -1 ||
              window.location.href.indexOf("/admin/processes-items") > -1 ||
              window.location.href.indexOf("/admin/processes-steps") > -1,
            // subNavigationItems: [
            //   {
            //     onClick: () => navigate("/admin/processes-items"),
            //     label: "Items",
            //     selected:
            //       window.location.href.indexOf("/admin/processes-items") > -1,
            //   },
            //   {
            //     onClick: () => navigate("/admin/processes-categories"),
            //     selected:
            //       window.location.href.indexOf("/admin/processes-categories") >
            //       -1,
            //     label: "Categories",
            //   },
            // ],
          },

          // {
          //   url: "/",
          //   onClick: () => navigate("/admin/users"),
          //   label: "Users",
          //   icon: CustomersMajor,
          //   selected: window.location.href.indexOf("/admin/users") > -1,
          // },
        ]}
      ></Navigation.Section>
      {/* <Navigation.Section
        separator
        items={[
          {
            icon: SettingsMajor,
            label: "Settings",
            url: "/admin/settings",
            selected:
              window.location.href.indexOf("/admin/settings") > -1 && true,
          },
        ]}
      /> */}
      <Navigation.Section
        title={t("nav_settings")}
        items={[
          {
            url: "/",
            onClick: () => navigate(`/${i18n.language}/admin/templates-items`),
            label: t("nav_templates"),
            icon: ChatMajor,
            selected:
              window.location.href.indexOf("/admin/templates-categories") >
                -1 ||
              window.location.href.indexOf("/admin/templates-items") > -1 ||
              window.location.href.indexOf("/admin/templates-steps") > -1,
            // subNavigationItems: [
            //   {
            //     onClick: () => navigate("/admin/templates-items"),
            //     label: "Items",
            //     selected:
            //       window.location.href.indexOf("/admin/templates-items") > -1,
            //   },
            //   {
            //     onClick: () => navigate("/admin/templates-categories"),
            //     selected:
            //       window.location.href.indexOf("/admin/templates-categories") >
            //       -1,
            //     label: "Categories",
            //   },
            // ],
          },
          {
            onClick: () => navigate(`/${i18n.language}/admin/users`),
            label: t("nav_users"),
            icon: CustomersMajor,
            selected: window.location.href.indexOf("/admin/users") > -1,
          },

          // {
          //   onClick: () => navigate("/admin/admin-users"),
          //   label: "Admin Users",
          //   icon: CustomersMajor,
          //   selected: window.location.href.indexOf("/admin/admin-users") > -1,
          // },
        ]}
        // action={{
        //   accessibilityLabel: "Add sales channel",
        //   icon: CirclePlusOutlineMinor,
        //   onClick: () => {},
        // }}
      />
    </Navigation>
  );
  const loadingMarkup = isLoading ? <Loading /> : null;

  const skipToContentTarget = (
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );

  const actualPageMarkup = (
    <Page title="Account">
      <Layout>
        {skipToContentTarget}
        <Layout.AnnotatedSection
          title="Account details"
          description="Jaded Pixel will use this as your account information."
        >
          <Card sectioned>
            <FormLayout>
              <TextField
                label="Full name"
                value={nameFieldValue}
                onChange={handleNameFieldChange}
              />
              <TextField
                type="email"
                label="Email"
                value={emailFieldValue}
                onChange={handleEmailFieldChange}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  const modalMarkup = (
    <Modal
      open={modalActive}
      onClose={toggleModalActive}
      title="Contact support"
      primaryAction={{
        content: "Send",
        onAction: toggleModalActive,
      }}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label="Subject"
            value={supportSubject}
            onChange={handleSubjectChange}
          />
          <TextField
            label="Message"
            value={supportMessage}
            onChange={handleMessageChange}
            multiline
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const theme = {
    colors: {
      surface: "#FFFFFF",
      onsurface: "#212B36",
    },
  };
  const logo = {
    width: 50,
    topBarSource: logoImg,
    url: `/${i18n.language}/admin/dashboard`,

    // accessibilityLabel: "Jaded Pixel",
  };
  useEffect(() => {
    document.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return (
    <div style={{ height: "500px" }}>
      <AppProvider
        // theme={theme}
        i18n={{
          Polaris: {
            Avatar: {
              label: "Avatar",
              labelWithInitials: "Avatar with initials {initials}",
            },
            ContextualSaveBar: {
              save: "Save",
              discard: "Discard",
            },
            TextField: {
              characterCount: "{count} characters",
            },
            TopBar: {
              toggleMenuLabel: "Toggle menu",

              SearchField: {
                clearButtonLabel: "Clear",
                search: "Search",
              },
            },
            Modal: {
              iFrameTitle: "body markup",
            },
            Frame: {
              skipToContent: "Skip to content",
              Navigation: {
                closeMobileNavigationLabel: "Close navigation",
              },
            },
          },
        }}
      >
        <Frame
          topBar={topBarMarkup}
          navigation={navigationMarkup}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
          skipToContentTarget={skipToContentRef.current}
          logo={logo}
        >
          {props.children}
        </Frame>
      </AppProvider>
    </div>
  );
}
export default React.memo(NavigationLayout);
