import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Text,
  Loading,
  InlineError,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EditProcessCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const handleChangeNameEn = (newValue) => {
    setItem({ ...item, nameEn: newValue });
    setNameError("");
  };
  const handleChangeNameAr = (newValue) => {
    setItem({ ...item, nameAr: newValue });
    setNameError("");
  };
  const [nameError, setNameError] = useState("");
  const [isSaving, setIsSaving] = useState(true);

  const [item, setItem] = useState({
    nameEn: "",
    nameAr: "",
  });
  useEffect(() => {
    axios
      .get(`/admin/process-categories/${id}`)
      .then((result) => {
        setIsSaving(false);
        setItem({
          nameEn: result?.data?.name?.en ? result.data.name.en : "",
          nameAr: result?.data?.name?.ar ? result.data.name.ar : "",
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Edit Category"
      breadcrumbs={[
        { onAction: () => navigate(`/admin/processes-categories`) },
      ]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                Name En
              </Text>
              <TextField
                value={item.nameEn}
                onChange={handleChangeNameEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                Name Ar
              </Text>
              <TextField
                value={item.nameAr}
                onChange={handleChangeNameAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={nameError} />
          {/* <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail size="large" source={item.image ? item.image : ""} />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div> */}
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .delete(`/admin/process-categories/${id}`)
                .then((result) => {
                  navigate(`/admin/processes-categories`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (!item.nameEn && !item.nameAr) {
      !item.nameEn &&
        !item.nameAr &&
        setNameError("Please enter a category name");
    } else {
      setIsSaving(true);
      const bodyObj = {
        name: { en: item.nameEn, ar: item.nameAr },
      };

      axios
        .patch(`admin/process-categories/${id}`, bodyObj)
        .then((res) => {
          navigate("/admin/processes-categories");
        })
        .catch((err) => console.log(""));
    }
  }
};
export default EditProcessCategory;
