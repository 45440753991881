import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  InlineError,
  Text,
  Page,
  AppProvider,
  FormLayout,
  Popover,
  TextField,
  Select,
  Loading,
  PageActions,
  Button,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useParams } from "react-router-dom";
import InputEmoji from "react-input-emoji";
import editCategory from "../../Assets/Images/Svgs/editCategory.svg";
import { useTranslation } from "react-i18next";

function EditComponent(props) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [questionError, setquestionError] = useState("");
  const [valueAnswer, setvalueAnswer] = useState("");
  const [answerError, setAnswerError] = useState("");
  const [selectError, setSelectError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const handleSelectChange = (newValue) => {
    setItem({ ...item, category: newValue });
  };
  const [options, setOptions] = useState([]);

  const handleChangeQuestion = (newValue) => {
    setItem({ ...item, question: newValue });
    setquestionError("");
  };
  const handleSelectChangeLanguage = (newValue) => {
    setItem({ ...item, language: newValue });
  };
  const optionsLanguage = [
    { label: "Arabic", value: "Arabic" },
    { label: "English", value: "English" },
  ];

  const [item, setItem] = useState({
    language: "",
    category: "",
    question: "",
  });
  useEffect(() => {
    console.log("props=", props);
    fetchData();
  }, []);

  async function fetchData() {
    let responseCategories = "";
    let responseItem = "";

    try {
      responseCategories = await axios.get(`admin/categories`);
      responseCategories &&
        setOptions(
          responseCategories.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseItem = await axios.get(
        `admin/templates/${parseInt(props.questionId)}?include=category`
      );
      console.log(responseItem);
      responseItem &&
        setItem({
          language: Object.keys(responseItem.data.answer)[0],
          category:
            responseItem?.data?.category.id !== null
              ? responseItem?.data?.category.id
              : "",
          question: responseItem?.data?.question[
            Object.keys(responseItem?.data?.question)[0]
          ]
            ? responseItem?.data?.question[
                Object.keys(responseItem.data.question)[0]
              ]
            : "",
        });
      responseItem?.data?.answer[Object.keys(responseItem.data.answer)[0]] &&
        setvalueAnswer(
          responseItem.data.answer[Object.keys(responseItem.data.answer)[0]]
        );
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }

  return (
    <Page
    // title="Edit Question"
    // breadcrumbs={[{ onAction: () => navigate("/admin/questions") }]}
    >
      {isSaving ? <Loading /> : null}
      <FormLayout>
        <FormLayout.Group condensed>
          <FormLayout>
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("dashboard_language")}
            </Text>
            <Select
              options={optionsLanguage}
              onChange={handleSelectChangeLanguage}
              value={item.language}
            />
          </FormLayout>
          <FormLayout>
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("category")}
              <span style={{ marginLeft: "0.4rem" }}>
                <img
                  src={editCategory}
                  width={19}
                  onClick={() =>
                    navigate(`/${i18n.language}/admin/questions-categories`)
                  }
                  style={{ marginLeft: "0.2rem", cursor: "pointer" }}
                ></img>
              </span>
            </Text>
            <Select
              options={options}
              onChange={handleSelectChange}
              value={item.category}
              error={selectError}
              placeholder={t("please_select")}
            />
          </FormLayout>
        </FormLayout.Group>

        <Text variant="bodyMd" as="p" fontWeight="semibold">
          {t("question")}
        </Text>

        <Editor
          apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
          value={item.question}
          init={{
            branding: false,
            height: 200,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
              "directionality",
            ],
            toolbar:
              "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help|ltr rtl|code",
          }}
          onEditorChange={handleChangeQuestion}
        />
        <InlineError message={questionError} />
        <Text variant="bodyMd" as="p" fontWeight="semibold">
          {t("answer")}
        </Text>

        <InputEmoji
          value={valueAnswer}
          onChange={setvalueAnswer}
          cleanOnEnter
          // onEnter={handleOnEnter}
          placeholder="Type a message"
        />
        <InlineError message={answerError} />
      </FormLayout>{" "}
      <PageActions
        primaryAction={{
          content: t("save"),
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: t("delete"),
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .delete(`/admin/templates/${props.questionId}`)
                .then((result) => {
                  setIsSaving(false);
                  props.handleRefreshPage();
                  //  navigate(`/admin/questions`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />{" "}
    </Page>
  );

  function handleSave() {
    if (!item.question || !valueAnswer || !item.category) {
      !valueAnswer && setAnswerError(t("field_required"));
      !item.question && setquestionError(t("field_required"));
      !item.category && setSelectError(t("please_select_category"));
    } else {
      setIsSaving(true);
      const bodyObj = {
        question: {},
        answer: {},
        category_id: item.category,
      };
      bodyObj["question"][item.language] = item.question;
      bodyObj["answer"][item.language] = valueAnswer;
      axios
        .patch(`/admin/templates/${props.questionId}`, bodyObj)
        .then((res) => {
          setIsSaving(false);
          props.handleRefreshPage();
          //  navigate("/admin/questions");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
}
export default EditComponent;
