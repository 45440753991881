import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Text,
  Loading,
  InlineError,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";

const AddStep = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [questionEnValue, setQuestionEnValue] = useState("");
  const handleChangeQuestionEn = useCallback((newValue) => {
    setQuestionEnValue(newValue);
    setQuestionError("");
  }, []);
  const [questionArValue, setQuestionArValue] = useState("");
  const handleChangeQuestionAr = useCallback((newValue) => {
    setQuestionArValue(newValue);
    setQuestionError("");
  }, []);
  const [questionError, setQuestionError] = useState("");
  const [answerEnValue, setAnswerEnValue] = useState("");
  const handleChangeAnswerEn = useCallback((newValue) => {
    setAnswerEnValue(newValue);
    setAnswerError("");
  }, []);
  const [answerArValue, setAnswerArValue] = useState("");
  const handleChangeAnswerAr = useCallback((newValue) => {
    setAnswerArValue(newValue);
    setAnswerError("");
  }, []);
  const [answerError, setAnswerError] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const [optionsCategories, setOptionsCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleSelectCategory = useCallback((newValue) => {
    setSelectedCategory(newValue);
    setCategoryError("");
  }, []);
  const [categoryError, setCategoryError] = useState("");
  const [textEnValue, setTextEnValue] = useState("");
  const handleChangeTextEn = (newValue) => {
    setTextEnValue(newValue);
  };
  const [textArValue, setTextArValue] = useState("");
  const handleChangeTextAr = (newValue) => {
    setTextArValue(newValue);
  };
  // useEffect(() => {
  //   axios
  //     .get(`/admin/process-categories`)
  //     .then((result) => {
  //       setIsSaving(false);
  //       setOptionsCategories(
  //         result.data.map((item, index) => {
  //           return {
  //             label: item.name[Object.keys(item.name)[0]],
  //             value: String(item.id),
  //           };
  //         })
  //       );
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  return (
    <Page
      title={t("steps_add_step")}
      breadcrumbs={[
        {
          onAction: () =>
            navigate(`/${i18n.language}/admin/processes-items/${id}/steps`),
        },
      ]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          {/* <TextStyle variation="strong">Category</TextStyle>
          <Select
            options={optionsCategories}
            value={selectedCategory}
            onChange={handleSelectCategory}
            placeholder="Please select a category"
            error={categoryError}
          /> */}
          {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Question En</TextStyle>
              <TextField
                value={questionEnValue}
                onChange={handleChangeQuestionEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Question Ar</TextStyle>
              <TextField
                value={questionArValue}
                onChange={handleChangeQuestionAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={questionError} /> */}
          <FormLayout.Group>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                {t("steps_step_english")}
              </Text>
              <TextField
                value={answerEnValue}
                onChange={handleChangeAnswerEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                {t("steps_step_arabic")}
              </Text>
              <TextField
                value={answerArValue}
                onChange={handleChangeAnswerAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={answerError} />
          <FormLayout.Group>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                {t("description_english")}
              </Text>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textEnValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help|ltr rtl|code",
                }}
                onEditorChange={handleChangeTextEn}
              />
            </FormLayout>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                {t("description_arabic")}
              </Text>
              <Editor
                apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
                value={textArValue}
                init={{
                  branding: false,
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help|ltr rtl|code",
                }}
                onEditorChange={handleChangeTextAr}
              />
            </FormLayout>
          </FormLayout.Group>

          {/* <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail
                size="large"
                source={imagePreview ? imagePreview : ""}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div> */}
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: t("save"),
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      // (!questionEnValue && !questionArValue) ||
      !answerEnValue &&
      !answerArValue
    ) {
      // !questionEnValue &&
      //   !questionArValue &&
      //   setQuestionError("Please enter a question");

      !answerEnValue && !answerArValue && setAnswerError(t("field_required"));
    } else {
      setIsSaving(true);
      const bodyObj = {
        // question: { en: questionEnValue, ar: questionArValue },
        answer: { en: answerEnValue, ar: answerArValue },
        description: { en: textEnValue, ar: textArValue },
        process_id: id,
      };

      axios
        .post("/admin/process-answers", bodyObj)
        .then((res) => {
          navigate(`/${i18n.language}/admin/processes-items/${id}/steps`);
        })
        .catch((err) => console.log(""));
    }
  }
};
export default AddStep;
