import React, { useCallback, useState, useEffect } from "react";
import {
  FormLayout,
  Page,
  Card,
  List,
  Text,
  Button,
  ActionList,
  Popover,
  Modal,
  Badge,
} from "@shopify/polaris";
import Chart from "react-apexcharts";
import "react-dynamic-charts/dist/index.css"; // Don't forget to import the styles
import totalEntries from "../Assets/Images/Svgs/totalEntries.svg";
import totalSearches from "../Assets/Images/Svgs/totalSearches.svg";
import contentViews from "../Assets/Images/Svgs/contentViews.svg";
import contentEdit from "../Assets/Images/Svgs/contentEdit.svg";
import newEntries from "../Assets/Images/Svgs/newEntries.svg";
import top from "../Assets/Images/Svgs/top.svg";
import language from "../Assets/Images/Svgs/language.svg";
import time from "../Assets/Images/Svgs/time.svg";
import { useTranslation } from "react-i18next";
import axios from "../Assets/Lib/axios";
import EllipsisText from "react-ellipsis-text";
import { useRef } from "react";

const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const refTimeFrameId = useRef(1);
  const [popupActive, setPopupActive] = useState(true);
  const options = {
    chart: {
      id: "basic-bar",
      toolbar: { show: false },
    },
    xaxis: {
      categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    },
    fill: {
      colors: ["#ff0054"],
    },
  };
  const series = [
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91, 45, 50, 49, 60, 70, 91],
    },
  ];
  const [activePopupLanguage, setActivePopupLanguage] = useState(false);
  const toggleActivePopupLanguage = useCallback(
    () => setActivePopupLanguage((activePopupLanguage) => !activePopupLanguage),
    []
  );

  const [timeFilterText, setTimeFilterText] = useState(
    t("dashboard_last_month")
  );
  const handleOnTimeClick = (param, text, id) => {
    setActivePopupTime(false);
    setTimeFilter(param);
    setTimeFilterText(text);
    refTimeFrameId.current = id;
  };

  const activatorPopupLanguage = (
    <Button onClick={toggleActivePopupLanguage} disclosure>
      <div className="rowDiv">
        <img
          src={language}
          width={19}
          className={i18n.language === "en" ? "me-2" : "ms-2"}
        ></img>
        {t("dashboard_language")}
      </div>
    </Button>
  );
  const [activePopupTime, setActivePopupTime] = useState(false);

  const toggleActivePopupTime = (activePopupTime) => {
    setActivePopupTime((activePopupTime) => !activePopupTime);
  };
  const activatorPopupTime = (
    <Button onClick={toggleActivePopupTime} disclosure>
      <div className="rowDiv">
        <img
          src={time}
          width={19}
          className={i18n.language === "en" ? "me-2" : "ms-2"}
        ></img>
        {timeFilterText}
      </div>
    </Button>
  );
  const [result, setResult] = useState(null);
  const [timeFilter, setTimeFilter] = useState("month");
  useEffect(() => {
    if (refTimeFrameId.current === 1) {
      setTimeFilterText(t("dashboard_last_month"));
    } else if (refTimeFrameId.current === 2) {
      setTimeFilterText(t("dashboard_last_three_months"));
    } else if (refTimeFrameId.current === 3) {
      setTimeFilterText(t("dashboard_last_year"));
    } else if (refTimeFrameId.current === 4) {
      setTimeFilterText(t("dashboard_14_days"));
    } else if (refTimeFrameId.current === 5) {
      setTimeFilterText(t("dashboard_yesterday"));
    } else if (refTimeFrameId.current === 6) {
      setTimeFilterText(t("dashboard_today"));
    }
  }, [i18n, i18n.language]);
  useEffect(() => {
    setPopupActive(true);
    axios
      .get(`/admin/stats?time=${timeFilter}`)
      .then((result) => {
        result.data?.searches_statistics.map((element, index) => {
          console.log("test=", element);
        });
        setPopupActive(false);
        console.log(result.data);
        setResult(result.data);
      })
      .catch((err) => console.log(err));
  }, [timeFilter]);

  return (
    <Page fullWidth title={t("nav_dashboard")}>
      {result ? (
        <FormLayout>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* <Popover
              active={activePopupLanguage}
              activator={activatorPopupLanguage}
              autofocusTarget="first-node"
              onClose={toggleActivePopupLanguage}
            >
              <ActionList
                actionRole="menuitem"
                items={[
                  {
                    content: "English",
                    // onAction: handleImportedAction,
                  },
                  {
                    content: "Arabic",
                    // onAction: handleExportedAction,
                  },
                ]}
              />
            </Popover> */}
            {/* <div className="ms-2 me-2"> */}
            <div>
              <Popover
                active={activePopupTime}
                activator={activatorPopupTime}
                onClose={toggleActivePopupTime}
              >
                <ActionList
                  actionRole="menuitem"
                  items={[
                    {
                      content: t("dashboard_last_month"),
                      onAction: () =>
                        handleOnTimeClick(
                          "month",
                          t("dashboard_last_month"),
                          1
                        ),
                    },
                    {
                      content: t("dashboard_last_three_months"),
                      onAction: () =>
                        handleOnTimeClick(
                          "3months",
                          t("dashboard_last_three_months"),
                          2
                        ),
                    },
                    {
                      content: t("dashboard_last_year"),
                      onAction: () =>
                        handleOnTimeClick("year", t("dashboard_last_year"), 3),
                    },

                    {
                      content: t("dashboard_14_days"),
                      onAction: () =>
                        handleOnTimeClick("14days", t("dashboard_14_days"), 4),
                    },
                    {
                      content: t("dashboard_yesterday"),
                      onAction: () =>
                        handleOnTimeClick(
                          "yesterday",
                          t("dashboard_yesterday"),
                          5
                        ),
                    },
                    {
                      content: t("dashboard_today"),
                      onAction: () =>
                        handleOnTimeClick("today", t("dashboard_today"), 6),
                    },
                    // {
                    //   content: t("dashboard_today"),
                    //   onAction: handleExportedAction,
                    // },
                    // {
                    //   content: t("dashboard_yesterday"),
                    //   onAction: handleExportedAction,
                    // },
                  ]}
                />
              </Popover>
            </div>
          </div>

          <FormLayout.Group condensed>
            <Card
              sectioned
              title={
                <div className="rowDiv">
                  <img
                    src={totalEntries}
                    width={25}
                    className={i18n.language === "en" ? "me-2" : "ms-2"}
                  ></img>

                  <Text variant="headingSm" as="h6">
                    {t("dashboard_total_entries")}
                  </Text>
                </div>
              }
            >
              <Text variant="heading3xl" as="h2">
                {result?.total_entries !== null ? result?.total_entries : ""}
              </Text>
            </Card>
            <Card
              sectioned
              title={
                <div className="rowDiv">
                  <img
                    src={newEntries}
                    width={25}
                    className={i18n.language === "en" ? "me-2" : "ms-2"}
                  ></img>

                  <Text variant="headingSm" as="h6">
                    {t("dashboard_new_entries")}
                  </Text>
                </div>
              }
            >
              <Text variant="heading3xl" as="h2">
                {result?.new_entries !== null ? result?.new_entries : ""}
              </Text>
            </Card>
            <Card
              sectioned
              title={
                <div className="rowDiv">
                  <img
                    src={totalSearches}
                    width={25}
                    className={i18n.language === "en" ? "me-2" : "ms-2"}
                  ></img>

                  <Text variant="headingSm" as="h6">
                    {t("dashboard_new_searches")}
                  </Text>
                </div>
              }
            >
              <Text variant="heading3xl" as="h2">
                {result?.new_searches !== null ? result?.new_searches : ""}
              </Text>
            </Card>
            <Card
              sectioned
              title={
                <div className="rowDiv">
                  <img
                    src={contentViews}
                    width={25}
                    className={i18n.language === "en" ? "me-2" : "ms-2"}
                  ></img>

                  <Text variant="headingSm" as="h6">
                    {t("dashboard_content_views")}
                  </Text>
                </div>
              }
            >
              <Text variant="heading3xl" as="h2">
                {result?.viewed_processes !== null
                  ? result?.viewed_processes
                  : ""}
              </Text>
            </Card>
            <Card
              sectioned
              title={
                <div className="rowDiv ">
                  <img
                    src={contentEdit}
                    width={25}
                    className={i18n.language === "en" ? "me-2" : "ms-2"}
                  ></img>

                  <Text variant="headingSm" as="h6">
                    {t("dashboard_content_edit")}
                  </Text>
                </div>
              }
            >
              <Text variant="heading3xl" as="h2">
                {result?.edited_processes !== null
                  ? result?.edited_processes
                  : ""}
              </Text>
            </Card>
          </FormLayout.Group>

          <FormLayout.Group condensed>
            <Card
              sectioned
              title={
                <div className="rowDiv">
                  <img
                    src={top}
                    width={25}
                    className={i18n.language === "en" ? "me-2" : "ms-2"}
                  ></img>

                  <Text variant="headingSm" as="h6">
                    {t("dashboard_top_questions")}
                  </Text>
                </div>
              }
            >
              <List type="number">
                {result?.top_questions_views &&
                result?.top_questions_views.length
                  ? result?.top_questions_views.map((element, index) => {
                      // return <List.Item key={index}>{element}</List.Item>;
                      return (
                        <List.Item key={index}>
                          <div>
                            <EllipsisText text={element[0]} length={40} />
                            <span className="dashboardBadge">
                              <Badge> {element[1]}</Badge>
                            </span>
                          </div>
                        </List.Item>
                      );
                    })
                  : null}
                {/* <List.Item>
                  <div>
                    How can I get assistance if I need it?
                    <span className="dashboardBadge">
                      <Badge>2</Badge>
                    </span>
                  </div>
                </List.Item> */}
              </List>
            </Card>
            <Card
              sectioned
              title={
                <div className="rowDiv">
                  <img
                    src={top}
                    width={25}
                    className={i18n.language === "en" ? "me-2" : "ms-2"}
                  ></img>

                  <Text variant="headingSm" as="h6">
                    {t("dashboard_top_processes")}
                  </Text>
                </div>
              }
            >
              <List type="number">
                {result?.top_processes_views &&
                result?.top_processes_views.length
                  ? result?.top_processes_views.map((element, index) => {
                      // return <List.Item key={index}>{element}</List.Item>;
                      return (
                        <List.Item key={index}>
                          <div>
                            <EllipsisText text={element[0]} length={40} />
                            <span className="dashboardBadge">
                              <Badge> {element[1]}</Badge>
                            </span>
                          </div>
                        </List.Item>
                      );
                    })
                  : null}
              </List>
            </Card>
            <Card
              sectioned
              title={
                <div className="rowDiv">
                  <img
                    src={top}
                    width={25}
                    className={i18n.language === "en" ? "me-2" : "ms-2"}
                  ></img>

                  <Text variant="headingSm" as="h6">
                    {t("dashboard_latest_copies")}
                  </Text>
                </div>
              }
            >
              <List type="number">
                {result?.latest_searches && result?.latest_searches.length
                  ? result?.latest_searches.map((element, index) => {
                      return (
                        <List.Item key={index}>
                          <EllipsisText text={element} length={40} />
                        </List.Item>
                      );
                    })
                  : null}
              </List>
            </Card>
          </FormLayout.Group>
          <FormLayout.Group>
            {/* <Card>
            <Card.Header title="QUESTIONS STATISTICS">
              <Popover
                active={false}
                activator={
                  <Button disclosure plain>
                    Last 14 days
                  </Button>
                }
                onClose={() => {}}
              >
                <ActionList
                  items={[{ content: "Gross Sales" }, { content: "Net Sales" }]}
                />
              </Popover>
            </Card.Header>
            <Chart options={options} series={series} type="bar" width="100%" />
          </Card>
          <Card>
            <Card.Header title="PROCESSES STATISTICS">
              <Popover
                active={false}
                activator={
                  <Button disclosure plain>
                    Last 14 days
                  </Button>
                }
                onClose={() => {}}
              >
                <ActionList
                  items={[{ content: "Gross Sales" }, { content: "Net Sales" }]}
                />
              </Popover>
            </Card.Header>
            <Chart options={options} series={series} type="bar" width="100%" />
          </Card> */}
            <Card>
              <Card.Header title={t("dashboard_searches")}>
                {/* <Popover
                active={false}
                activator={
                  <Button disclosure plain>
                    Last 14 days
                  </Button>
                }
                onClose={() => {}}
              >
                <ActionList
                  items={[{ content: "Gross Sales" }, { content: "Net Sales" }]}
                />
              </Popover> */}
              </Card.Header>
              <Chart
                options={{
                  chart: {
                    id: "basic-bar",
                    toolbar: { show: false },
                  },
                  xaxis: {
                    categories: Array.from(
                      { length: result?.searches_statistics.length },
                      (_, index) => index + 1
                    ).map((item, idx) => {
                      // return ``;
                      return `${item}`;
                    }),
                    labels: {
                      show: false,
                    },
                    // labels: {
                    //   rotate: -90,
                    // },
                  },
                  fill: {
                    colors: ["#ff0054"],
                  },
                }}
                // series={[
                //   {
                //     name: "series-1",
                //     data: [
                //       30, 40, 45, 50, 49, 60, 70, 91, 45, 50, 49, 60, 70, 91,
                //     ],
                //   },
                // ]}
                series={[
                  {
                    name: "",
                    data:
                      result?.searches_statistics &&
                      result?.searches_statistics?.length &&
                      result?.searches_statistics.map((element, index) => {
                        return element;
                      }),
                  },
                ]}
                type="bar"
                width="100%"
                height={250}
              />
            </Card>
          </FormLayout.Group>
        </FormLayout>
      ) : null}

      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
};

export default Dashboard;
