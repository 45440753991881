import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  InlineError,
  Text,
  Page,
  AppProvider,
  FormLayout,
  Popover,
  Select,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useParams } from "react-router-dom";
import InputEmoji from "react-input-emoji";

function EditQuestion() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [questionError, setquestionError] = useState("");
  const [valueAnswer, setvalueAnswer] = useState("");
  const [answerError, setAnswerError] = useState("");
  const [selectError, setSelectError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const handleSelectChange = (newValue) => {
    setItem({ ...item, category: newValue });
  };
  const [options, setOptions] = useState([]);

  const handleChangeQuestion = (newValue) => {
    setItem({ ...item, question: newValue });
    setquestionError("");
  };
  const handleSelectChangeLanguage = (newValue) => {
    setItem({ ...item, language: newValue });
  };
  const optionsLanguage = [
    { label: "Arabic", value: "Arabic" },
    { label: "English", value: "English" },
  ];

  const [item, setItem] = useState({
    language: "",
    category: "",
    question: "",
  });
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    let responseCategories = "";
    let responseItem = "";

    try {
      responseCategories = await axios.get(`admin/categories`);
      responseCategories &&
        setOptions(
          responseCategories.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseItem = await axios.get(`admin/templates/${id}?include=category`);
      responseItem &&
        setItem({
          language: Object.keys(responseItem.data.answer)[0],
          category:
            responseItem?.data?.category.id !== null
              ? responseItem?.data?.category.id
              : "",
          question: responseItem?.data?.question[
            Object.keys(responseItem?.data?.question)[0]
          ]
            ? responseItem?.data?.question[
                Object.keys(responseItem.data.question)[0]
              ]
            : "",
        });
      responseItem?.data?.answer[Object.keys(responseItem.data.answer)[0]] &&
        setvalueAnswer(
          responseItem.data.answer[Object.keys(responseItem.data.answer)[0]]
        );
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }

  return (
    <AppProvider>
      <Page
        title="Edit Question"
        breadcrumbs={[{ onAction: () => navigate("/admin/questions") }]}
      >
        <Card sectioned>
          <FormLayout>
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              Language
            </Text>
            <Select
              options={optionsLanguage}
              onChange={handleSelectChangeLanguage}
              value={item.language}
            />
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              Category
            </Text>
            <Select
              options={options}
              onChange={handleSelectChange}
              value={item.category}
              error={selectError}
              placeholder="Please Select"
            />

            <Text variant="bodyMd" as="p" fontWeight="semibold">
              Question
            </Text>

            <Editor
              apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
              value={item.question}
              init={{
                branding: false,
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                  "directionality",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help|ltr rtl|code",
              }}
              onEditorChange={handleChangeQuestion}
            />
            <InlineError message={questionError} />
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              Answer
            </Text>

            <InputEmoji
              value={valueAnswer}
              onChange={setvalueAnswer}
              cleanOnEnter
              // onEnter={handleOnEnter}
              placeholder="Type a message"
            />
            <InlineError message={answerError} />
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: "Save",
            onClick: handleSave,
            loading: isSaving && true,
          }}
          secondaryActions={[
            {
              content: "Delete",
              destructive: true,
              loading: isSaving && true,
              onClick: () => {
                setIsSaving(true);
                axios
                  .delete(`/admin/templates/${id}`)
                  .then((result) => {
                    navigate(`/admin/questions`);
                  })
                  .catch((err) => console.log(err));
              },
            },
          ]}
        />
      </Page>
    </AppProvider>
  );

  function handleSave() {
    if (!item.question || !valueAnswer || !item.category) {
      !valueAnswer && setAnswerError("This field is required");
      !item.question && setquestionError("This field is required");
      !item.category && setSelectError("Category ID is required");
    } else {
      setIsSaving(true);
      const bodyObj = {
        question: {},
        answer: {},
        category_id: item.category,
      };
      bodyObj["question"][item.language] = item.question;
      bodyObj["answer"][item.language] = valueAnswer;
      axios
        .patch(`/admin/templates/${id}`, bodyObj)
        .then((res) => {
          navigate("/admin/questions");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
}
export default EditQuestion;
