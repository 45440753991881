import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  ResourceItem,
  ResourceList,
  Thumbnail,
  Caption,
  Modal,
  InlineError,
  DropZone,
  Checkbox,
  Stack,
  Text,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  ActionList,
  Popover,
  Select,
  Card,
  Tabs,
  PageActions,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AddUser() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [valueName, setValueName] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [valuePassword, setValuePassword] = useState("");
  const options = [
    { label: "Admin", value: "admin" },
    { label: "Editor", value: "editor" },
    { label: "Agent", value: "agent" },
  ];
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const [selected, setSelected] = useState("admin");
  const [popupActive, setPopupActive] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  return (
    <AppProvider>
      <Page
        title={t("users_add_user")}
        breadcrumbs={[
          { onAction: () => navigate(`/${i18n.language}/admin/users`) },
        ]}
      >
        <Card sectioned>
          <FormLayout>
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("name")}
            </Text>
            <TextField
              value={valueName}
              onChange={handleChangeName}
              error={nameError}
            />
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("email")}
            </Text>
            <TextField
              value={valueEmail}
              onChange={handleChangeEmail}
              error={emailError}
            />
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("password")}
            </Text>
            <TextField
              value={valuePassword}
              onChange={handleChangePassword}
              error={passwordError}
            />
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("usrs_select_account_type")}
            </Text>
            <Select
              options={options}
              onChange={handleSelectChange}
              value={selected}
            />
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: t("save"),
            onClick: handleSave,
          }}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </AppProvider>
  );

  function handleChangeName(valueName) {
    setValueName(valueName);
    setNameError("");
  }
  function handleChangeEmail(valueEmail) {
    setValueEmail(valueEmail);
    setEmailError("");
  }
  function handleChangePassword(valuePassword) {
    setValuePassword(valuePassword);
    setPasswordError("");
  }
  function handleSave() {
    setPopupActive(true);
    const bodyObj = {
      name: valueName,
      email: valueEmail,
      password: valuePassword,
      account_type: selected,
    };
    axios
      .post("/admin/users", bodyObj)
      .then((res) => {
        navigate(`${i18n.language}/admin/users`);
      })
      .catch(function (error) {
        setPopupActive(false);
        error.response.data.errors.name &&
          setNameError(error.response.data.errors.name);
        error.response.data.errors.email &&
          setEmailError(error.response.data.errors.email);
        error.response.data.errors.password &&
          setPasswordError(error.response.data.errors.password);
      });
  }
}
export default AddUser;
