import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Button,
  Text,
  Loading,
  InlineError,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { useParams } from "react-router-dom";

const EditMessage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [messageValue, setMessageValue] = useState("");
  const handleChangeMessage = (newValue) => {
    setItem({ ...item, message: newValue });
    setMessageError("");
  };
  const [messageError, setMessageError] = useState("");
  const [isSaving, setIsSaving] = useState(true);
  const [categoriesError, setCategoriesError] = useState("");
  const [placementError, setPlacementError] = useState("");
  const [sentimentsError, setSentimentsError] = useState("");

  const [optionsLanguages, setOptionsLanguages] = useState([
    { label: "English", value: "en" },
    { label: "Arabic", value: "ar" },
  ]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const handleSelectLanguage = (newValue) => {
    setItem({ ...item, language: newValue });
    setLanguageError("");
  };
  const [languageError, setLanguageError] = useState("");
  const [item, setItem] = useState({
    language: "",
    message: "",
    placement: "",
  });
  useEffect(() => {
    fetchData();
  }, []);
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [optionsPlacements, setOptionsPlacements] = useState([]);
  const [optionsSentiments, setOptionsSentiments] = useState([]);
  const [selectedSentiments, setSelectedSentiments] = useState([]);
  const [selectedPlacement, setSelectedPlacement] = useState("");
  const handleSelectChangePlacement = (newValue) => {
    setItem({ ...item, placement: newValue });
    setPlacementError("");
  };
  async function fetchData() {
    let responseCategories = "";
    let responseSentiments = "";
    let responsePlacements = "";
    let responseMessage = "";
    try {
      responseCategories = await axios.get(`/admin/template-categories`);
      responseCategories?.data &&
        setOptionsCategories(
          responseCategories.data.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseSentiments = await axios.get(`/admin/sentiments`);
      responseSentiments?.data &&
        setOptionsSentiments(
          responseSentiments.data.map((item, index) => {
            return {
              label: item.name,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responsePlacements = await axios.get(`/admin/placements`);
      responsePlacements?.data &&
        setOptionsPlacements(
          responsePlacements.data.map((item, index) => {
            return {
              label: item.name,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }
    try {
      responseMessage = await axios.get(`/admin/messages/${id}`);
      console.log(responseMessage.data);
      responseMessage?.data &&
        setItem({
          message: responseMessage?.data.message
            ? responseMessage?.data.message
            : "",
          placement:
            responseMessage?.data.placement_id !== null
              ? String(responseMessage?.data.placement_id)
              : "",
          language: responseMessage?.data.language
            ? responseMessage?.data.language
            : "",
        });

      responseMessage?.data.sentiments &&
        setSelectedSentiments(
          responseMessage?.data.sentiments.map((item, index) => {
            return {
              label: item.name,
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
      responseMessage?.data.template_categories &&
        setSelectedCategories(
          responseMessage?.data.template_categories.map((item, index) => {
            return {
              label: item.name[Object.keys(item.name)[0]],
              value: String(item.id),
              key: String(item.id),
            };
          })
        );
    } catch (error) {
      console.log(error);
    }

    setIsSaving(false);
  }

  return (
    <Page
      title="Edit Template"
      breadcrumbs={[
        {
          onAction: () => navigate(`/admin/templates-items`),
        },
      ]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <Text variant="bodyMd" as="p" fontWeight="semibold">
            Language
          </Text>
          <Select
            options={optionsLanguages}
            value={item.language}
            onChange={handleSelectLanguage}
            placeholder="Please select a language"
            error={languageError}
          />
          <Text variant="bodyMd" as="p" fontWeight="semibold">
            Message
          </Text>
          <TextField
            value={item.message}
            onChange={handleChangeMessage}
            error={messageError}
          />
          {/* <Editor
            apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
            value={item.message}
            init={{
              branding: false,
              height: 200,
              menubar: false,
              plugins: [
                "emoticons textcolor advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount image",
              ],
              toolbar:
                "emoticons undo redo | formatselect | bold italic forecolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help|code",
              file_browser_callback_types: "image",
              file_picker_callback: function (callback, value, meta) {
                if (meta.filetype == "image") {
                  var input = document.getElementById("my-file");
                  input.click();
                  input.onchange = function () {
                    var file = input.files[0];
                    console.log(file);
                    var reader = new FileReader();
                    reader.onload = function (e) {
                      console.log("name", e.target.result);
                      const form_data = new FormData();
                      form_data.append("image", file);
                      return axios
                        .post("/admin/v1/images", form_data)
                        .then((res) => {
                          callback(res.data.path, {
                            alt: file.name,
                          });
                        })
                        .catch((err) => console.log(""));
                    };
                    reader.readAsDataURL(file);
                  };
                }
              },
            }}
            onEditorChange={handleChangeMessage}
          />
          <InlineError message={messageError} /> */}
          <Text variant="bodyMd" as="p" fontWeight="semibold">
            Placement
          </Text>
          <Select
            options={optionsPlacements}
            onChange={handleSelectChangePlacement}
            value={item.placement}
            placeholder="Please select"
            error={placementError}
          />
          <FormLayout.Group>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                Categories
                <span style={{ marginLeft: "0.4rem" }}>
                  <Button
                    plain
                    onClick={() => navigate(`/admin/templates-categories`)}
                  >
                    Edit
                  </Button>
                </span>
              </Text>
              <MultiSelect
                options={optionsCategories}
                value={selectedCategories}
                onChange={setSelectedCategories}
                labelledBy="Please Select"
              />
              <InlineError message={categoriesError} />
            </FormLayout>
            <FormLayout>
              <Text variant="bodyMd" as="p" fontWeight="semibold">
                Sentiments
              </Text>
              <MultiSelect
                options={optionsSentiments}
                value={selectedSentiments}
                onChange={setSelectedSentiments}
                labelledBy="Please Select"
              />
              <InlineError message={sentimentsError} />
            </FormLayout>
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .delete(`/admin/messages/${id}`)
                .then((result) => {
                  navigate(`/admin/templates-items`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (
      !item.message ||
      !item.language ||
      !item.placement ||
      !selectedCategories.length ||
      !selectedSentiments.length
    ) {
      !item.message && setMessageError("This field is required");
      !item.language && setLanguageError("Please select a language");
      !item.placement && setPlacementError("Please select a placement");
      !selectedCategories.length &&
        setCategoriesError("Please select at least one category");
      !selectedSentiments.length &&
        setSentimentsError("Please select at least one sentiment");
    } else {
      setIsSaving(true);
      const bodyObj = {
        message: item.message,
        language: item.language,
        placement_id: item.placement,
        template_categories: selectedCategories.map((item) => item.value),
        sentiments: selectedSentiments.map((item) => item.value),
      };

      axios
        .patch(`/admin/messages/${id}`, bodyObj)
        .then((res) => {
          navigate("/admin/templates-items");
        })
        .catch((err) => console.log(""));
    }
  }
};
export default EditMessage;
