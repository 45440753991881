import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Text,
  Loading,
  InlineError,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";

const EditComponent = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  // const { stepId, stepId } = useParams();
  const handleChangeQuestionEn = (newValue) => {
    setItem({ ...item, questionEn: newValue });
    setQuestionError("");
  };
  const handleChangeQuestionAr = (newValue) => {
    setItem({ ...item, questionAr: newValue });
    setQuestionError("");
  };
  const handleChangeAnswerEn = (newValue) => {
    setItem({ ...item, answerEn: newValue });
    setAnswerError("");
  };
  const handleChangeAnswerAr = (newValue) => {
    setItem({ ...item, answerAr: newValue });
    setAnswerError("");
  };
  const handleChangeTextEn = (newValue) => {
    setItem({ ...item, textEn: newValue });
  };
  const handleChangeTextAr = (newValue) => {
    setItem({ ...item, textAr: newValue });
  };
  const [answerError, setAnswerError] = useState("");
  const [questionError, setQuestionError] = useState("");
  const [isSaving, setIsSaving] = useState(true);

  const [item, setItem] = useState({
    questionEn: "",
    questionAr: "",
    answerEn: "",
    answerAr: "",
    textEn: "",
    textAr: "",
  });
  const [optionsCategories, setOptionsCategories] = useState([]);
  const handleSelectCategory = (newValue) => {
    setItem({ ...item, category: newValue });
    setCategoryError("");
  };
  const [categoryError, setCategoryError] = useState("");

  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseAnswer = "";

    try {
      responseAnswer = await axios.get(
        `/admin/process-answers/${id}/${parseInt(props.stepId)}`
      );
      responseAnswer?.data &&
        setItem({
          // questionEn: responseAnswer?.data?.question?.en
          //   ? responseAnswer.data.question.en
          //   : "",
          // questionAr: responseAnswer?.data?.question?.ar
          //   ? responseAnswer.data.question.ar
          //   : "",
          answerEn: responseAnswer?.data?.answer?.en
            ? responseAnswer.data.answer.en
            : "",
          answerAr: responseAnswer?.data?.answer?.ar
            ? responseAnswer.data.answer.ar
            : "",
          textEn: responseAnswer?.data?.description?.en
            ? responseAnswer.data.description.en
            : "",
          textAr: responseAnswer?.data?.description?.en
            ? responseAnswer.data.description.en
            : "",
        });
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  }
  return (
    <Page
    // title="Edit Step"
    // breadcrumbs={[
    //   {
    //     onAction: () => navigate(`/admin/processes-items/${stepId}/steps`),
    //   },
    // ]}
    >
      {isSaving ? <Loading /> : null}
      {/* <Card sectioned> */}
      <FormLayout>
        {/* <TextStyle variation="strong">Category</TextStyle>
          <Select
            options={optionsCategories}
            value={item.category}
            onChange={handleSelectCategory}
            placeholder="Please select a category"
            error={categoryError}
          /> */}
        {/* <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Question En</TextStyle>
              <TextField
                value={item.questionEn}
                onChange={handleChangeQuestionEn}
                align="left"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Question Ar</TextStyle>
              <TextField
                value={item.questionAr}
                onChange={handleChangeQuestionAr}
                align="right"
              />
            </FormLayout>
          </FormLayout.Group>
          <InlineError message={questionError} /> */}
        <FormLayout.Group condensed>
          <FormLayout>
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("steps_step_english")}
            </Text>
            <TextField
              value={item.answerEn}
              onChange={handleChangeAnswerEn}
              align="left"
            />
          </FormLayout>
          <FormLayout>
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("steps_step_arabic")}
            </Text>
            <TextField
              value={item.answerAr}
              onChange={handleChangeAnswerAr}
              align="right"
            />
          </FormLayout>
        </FormLayout.Group>
        <InlineError message={questionError} />
        <FormLayout.Group>
          <FormLayout>
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("description_english")}
            </Text>
            <Editor
              apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
              value={item.textEn}
              init={{
                branding: false,
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help|ltr rtl|code",
              }}
              onEditorChange={handleChangeTextEn}
            />
          </FormLayout>
          <FormLayout>
            <Text variant="bodyMd" as="p" fontWeight="semibold">
              {t("description_arabic")}
            </Text>
            <Editor
              apiKey="7e5qugyswmfji644700m0pos9pozwpesrtdbyflsqx2s0zsf"
              value={item.textAr}
              init={{
                branding: false,
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \bullist numlist outdent indent | removeformat | help|ltr rtl|code",
              }}
              onEditorChange={handleChangeTextAr}
            />
          </FormLayout>
        </FormLayout.Group>
        {/* <TextStyle variation="strong">Image</TextStyle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ float: "left" }}>
              <Thumbnail size="large" source={item.image ? item.image : ""} />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <DropZone onDrop={handleDropZone} onDropAccepted={sendImage}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
          </div> */}
      </FormLayout>

      <PageActions
        primaryAction={{
          content: t("save"),
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: t("delete"),
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .delete(`/admin/process-answers/${props.stepId}`)
                .then((result) => {
                  setIsSaving(false);
                  props.handleRefreshPage();
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (!item.answerEn && !item.answerAr) {
      !item.answerEn && !item.answerAr && setAnswerError(t("field_required"));
    } else {
      setIsSaving(true);
      const bodyObj = {
        answer: { en: item.answerEn, ar: item.answerAr },
        process_id: id,
        description: { en: item.textEn, ar: item.textAr },
      };

      axios
        .patch(`/admin/process-answers/${parseInt(props.stepId)}`, bodyObj)
        .then((res) => {
          setIsSaving(false);
          props.handleRefreshPage();
        })
        .catch((err) => console.log(""));
    }
  }
};
export default EditComponent;
