import React, { useCallback, useState, useEffect } from "react";
import {
  Thumbnail,
  Caption,
  DropZone,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Loading,
  Card,
  Modal,
  PageActions,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import Cookies from "js-cookie";
import history from "../../Assets/Lib/history";
import { useNavigate } from "react-router-dom";

const AddAdminUser = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordConfirmationValue, setPasswordConfirmationValue] =
    useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const handleChangeFirstName = useCallback((newValue) => {
    setFirstNameValue(newValue);
    setFirstNameError("");
  }, []);
  const handleChangeLastName = useCallback((newValue) => {
    setLastNameValue(newValue);
    setLastNameError("");
  }, []);
  const handleChangeEmail = useCallback((newValue) => {
    setEmailValue(newValue);
    setEmailError("");
  }, []);
  const handleChangePassword = useCallback((newValue) => {
    setPasswordValue(newValue);
    setPasswordError("");
  }, []);
  const handleChangePasswordConfirmation = useCallback((newValue) => {
    setPasswordConfirmationValue(newValue);
    setPasswordError("");
  }, []);
  return (
    <Page
      title="Add User"
      breadcrumbs={[{ content: "Admin Users", url: "/admin/admin-users" }]}
    >
      {isSaving ? <Loading /> : null}

      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              value={firstNameValue}
              onChange={handleChangeFirstName}
              label="First Name"
              error={firstNameError}
            />
            <TextField
              value={lastNameValue}
              onChange={handleChangeLastName}
              label="Last Name"
              error={lastNameError}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={emailValue}
              onChange={handleChangeEmail}
              label="Email"
              error={emailError}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={passwordValue}
              onChange={handleChangePassword}
              label="Password"
              error={passwordError}
              helpText="Password must be at least 6 characters"
            />
            <TextField
              value={passwordConfirmationValue}
              onChange={handleChangePasswordConfirmation}
              label="Confirm Password"
            />
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      !firstNameValue ||
      !lastNameValue ||
      !emailValue ||
      (passwordValue.length < 6 && passwordValue) ||
      (emailValue &&
        !emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
      passwordValue.length < 6 ||
      passwordConfirmationValue != passwordValue
    ) {
      !firstNameValue && setFirstNameError("This field is required");
      !lastNameValue && setLastNameError("This field is required");
      !emailValue && setEmailError("This field is required");
      emailValue &&
        !emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) &&
        setEmailError("Please enter a valid email");
      !passwordValue && setPasswordError("This field is required");
      passwordValue &&
        passwordValue.length < 6 &&
        setPasswordError("Password must be at least 6 characters");
      passwordConfirmationValue != passwordValue &&
        setPasswordError("Password are not matching");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      firstNameValue && form_data.append("first_name", firstNameValue);
      lastNameValue && form_data.append("last_name", lastNameValue);
      emailValue && form_data.append("email", emailValue);
      passwordValue && form_data.append("password", passwordValue);
      passwordConfirmationValue &&
        form_data.append("password_confirmation", passwordConfirmationValue);

      axios
        .post("/admin/administrators", form_data)
        .then((res) => {
          navigate("/admin/admin-users");
        })
        .catch((err) => console.log(err));
    }
  }
};
export default AddAdminUser;
